@media only screen and (min-width: 576px) {

	.mw-md-1000 {
		max-width: 1000px;
	}

	.content-padding {
		padding: 32px 22px;
	}

	ion-content.background-md-lightgrey {
		--ion-background-color: #f2f2f7;
	}

	.nk-block .heading h3 {
		margin: 20px 0 20px 30px;
	}

	#add-kursant .nk-block {
		margin-top: 30px;
	}

	.mbsc-error-message-box {
		top: 0;
		border-top-right-radius: 8px;
	}

	/* .form-primary-heading {
		font-size: 29px;
		margin-bottom: 6px !important;
		text-align: center;
		padding: 7px 17px;
		background: var(--ion-color-primary);
		color: white;
		border-radius: 31px;
		box-shadow: rgb(50 50 93 / 45%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
		letter-spacing: 0.2px;
		margin-left: 24px;
		width: fit-content;
		display: block;
		margin-right: auto;
		margin-left: auto;
	} */

	.form-primary-heading {
		font-size: 30px;
		padding: 7px 6px;
		color: white;
		border-radius: 31px;
		letter-spacing: 0.2px;
		display: block;
	}

	.form-primary {
		border: 3px solid var(--background-secondary);
		margin: 10px;
		margin-top: 0;
		padding: 17px 10px;
		/* border-radius: 9px; */
		background: var(--background-secondary);
		/* box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px; */
	}


	.nk-block-lg {
		padding: 20px
	}

	.mbsc-picker.mbsc-ios.mbsc-popup-round .mbsc-popup {
		transform: translate(0, -63px);
	}

	.mbsc-picker .mbsc-popup-pop-in {
		opacity: 1;
		-webkit-animation: custom-mbsc-pop-in .15s cubic-bezier(0,0,.2,1);
		animation: custom-mbsc-pop-in .15s cubic-bezier(0,0,.2,1)
	}
	
	.mbsc-picker .mbsc-popup-pop-out {
		opacity: 0;
		-webkit-animation: custom-mbsc-pop-out 75ms cubic-bezier(.4,0,1,1);
		animation: custom-mbsc-pop-out 75ms cubic-bezier(.4,0,1,1)
	}

	@-webkit-keyframes custom-mbsc-pop-in {
		0% {
			opacity: 1;
		}
	
		to {
			opacity: 1;
		}
	}
	
	@keyframes custom-mbsc-pop-in {
		0% {
			opacity: 1;
		}
	
		to {
			opacity: 1;
		}
	}

	
	@-webkit-keyframes custom-mbsc-pop-out {
		0% {
			opacity: 0;
		}

		to {
			opacity: 0;
		}
	}

	@keyframes custom-mbsc-pop-out {
		0% {
			opacity: 0;
		}

		to {
			opacity: 0;
		}
	}


	.border-bottom-radius-0 {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	.mbsc-ios.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-item {
		margin: 0 !important;
    	border-radius: 0;
		font-size: 17px;
	}

	.mbsc-ios.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-wrapper {
		margin: 0 !important;
	}

	.mbsc-scroller-wheel-wrapper {
		padding-bottom: 0 !important;
	}

	.mbsc-textarea-wrapper textarea {
		padding-top: 7px !important;
	}

	.mbsc-popup-wrapper:not(.mbsc-picker) .mbsc-popup-body {
		border: solid 1.5px var(--ion-color-primary);
	}

	.pxp-jobs-card-1-container .pxp-jobs-card-1.pxp-has-border {
		transition: box-shadow 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	}

	.pxp-jobs-card-1-container:hover .pxp-jobs-card-1.pxp-has-border {
		box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
	}

	.find-jobs-button {
		margin-right: 10px !important;
		font-size: 18px !important;
		height: 63px !important;
	}

	.loader-container-secondary {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-right: -50%;
		transform: translate(-50%, -50%);
	}

	/* .container-margin-header-responsive {
		margin-top: 120px;
	} */

	ion-title {
		position: unset !important;
	}
	
	ion-title .pxp-logo {
		display: initial;
	}
	
	.add-job-container {
		padding: 80px 0
	}

	.custom-alert-class .alert-wrapper {
		max-width: none !important;
    	width: 650px !important;
	}

	.add-job-container {
		background-color: var(--pxpMainColorLight);
	}

	.mbsc-ios.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-wrapper {
		padding: 0 !important
	}

}