.pxp-jobs-card-1-container {
    transition: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-delay: 0.15s;
}

.pxp-jobs-card-1-top {
    height: 100%;
    margin: 0 auto;
    display: grid;
}

.pxp-jobs-card-1-type {
    text-align: justify;
}

.jobster .pxp-jobs-card-1-type, .jobster .pxp-jobs-card-2-type, .jobster .pxp-jobs-card-3-type {
    padding-left: 0;
}


@media only screen and (min-width: 576px) {
    .pxp-jobs-card-1-title {
        font-size: 20px !important;
    }
    .post-categories {
        margin-top: 20px;
    }
    .post-categories-mobile {
        display: none !important;
    }
    .pxp-jobs-card-1-type {
        max-height: 208px;
        overflow: hidden;
    }
}

@media only screen and (max-width: 576px) {
    .pxp-jobs-card-1-title {
        font-size: 17px !important;
        margin-top: 0px;
    }
    .pxp-jobs-card-1-type {
        max-height: 117px;
        overflow: hidden;
    }
    .pxp-jobs-card-1-bottom-left {
        padding-right: 0;
        /* margin-bottom: 20px; */
        text-align: center;
    }
    .post-categories {
        margin-top: 0;
    }
    .post-categories-desktop {
        display: none !important;
    }
    .post-categories-mobile {
        text-align: center;
    }
}


.jobster .pxp-jobs-card-1-bottom .main-page-button {
    margin: 0 !important;
}