@media only screen and (max-width: 576px) {

    .fade-header-arrow ion-toolbar {
        padding-left: 0 !important;
    }
    
    .fade-header-arrow ion-title {
        padding-left: 0 !important;
    }
    
    .fade-header {
        font-size: 18px;
        font-family: Nunito;
        font-weight: 700;
        opacity: 1;
    }
    
    .fade-header-long {   
        text-overflow: inherit;
        white-space: normal;
        line-height: 20px;
        font-weight: 500;
        max-height: 40px;
    }
    
    .fade-header-long strong {   
        font-weight: 700;
    }

}