.btn-login {
    margin: 0 !important;
    height: 44px;
}



@media only screen and (min-width: 576px) {
    .brand-logo {
        display: none;
    }
    .nk-auth-body{
        background: #fbfbfb;
        box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px !important;
        border-radius: 15px;
    }
    .nk-auth-footer {
        padding-bottom: 0;
    }
    .nk-auth-container {
        background: #6576ff;
    }
    .nk-auth-body{
        opacity: 0;
        animation: 150ms ease 0s 1 normal forwards running slide-up;
        animation-delay: 100ms;
    }
}
  
@media only screen and (max-width: 576px)  {
    .nk-auth-footer {
        padding-bottom: 12px;
        padding: 12px 6px;
        height: 90px;
        width: 100%;
        max-width: unset !important;
    }

    .nk-auth-footer .nav-item .nav-link {
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .brand-logo {
        position: relative;
        height: 62px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .brand-logo ion-icon {
        height: 62px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
    }

    .nk-auth-body {
        padding: 4.25rem 1.25rem;
        /* max-height: 100vh; */
        overflow: hidden;
    }

}
/* 
@media only screen and (max-width: 356px), screen and (max-height: 627px) {
    .nk-auth-body {
        height: 100vh !important;
        margin-top: 80px !important;
        margin-bottom: 65px;
    }
    .nk-auth-body {
        max-height: unset !important;
    }
    .nk-auth-footer {
        bottom: -10px;
    }
} */

.nk-auth-body .ion-page {
    display: contents !important;
}

.nk-auth-body ion-router-outlet {
    display: contents !important;
}

#pin-grid {
    padding-left: 0;
    padding-right: 0;
}
