.popup-dropdown-custom .link-list {
	padding: 0.5rem 0px;
}

.popup-dropdown-custom .link-list li a {
	font-size: 16px;
    color: black;
    transition: 0.2s ease-in-out transform;
}

.popup-dropdown-custom .link-list li a span {
    margin-left: 13px;
}



.dashlite .popup-dropdown-custom .link-list a em {
    font-weight: 500;
    min-width: 28px;
    text-align: center;
    font-size: 22px;
}


@media only screen and (min-width: 576px) {
    .dashlite .popup-dropdown-custom .link-list a:hover {
        transform: translateX(5px);
    }
    .dashlite .popup-dropdown-custom .link-list a:hover em {
        font-weight: 900 !important;
    }
}

@media only screen and (max-width: 576px) {
    .dashlite .link-list a:hover {
        color: black;
    }
}

.dashlite .dropdown-inner + .dropdown-inner {
    border-top: unset;
}