.jobster .main-page-button {
    width: 54px;
    height: 54px;
    border: 0 none;
    margin-right: 13px;
    border-radius: 27px;
    color: #fff;
    background-color: var(--pxpMainColor);
    transition: var(--pxpHoverTransition);
    transition-property: background-color;
}

.jobster .main-page-button {
    height: 66px;
    width: auto;
    border-radius: 20px;
    margin-right: 20px;
    
}



.jobster .main-page-button:hover {
    background-color: var(--pxpMainColorDark);
}


.pulse-button {
    animation: pulse 3s infinite;
    transform: translate3d(0, 0, 0);
  }
  .pulse-button:hover {
    animation: none;
  }
  
  @keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.95);
    }
      100% {
        transform: scale(1);
    }
  }

.dashlite .overline-main-page{
    margin-top: 33px;
}



.jobster .pxp-hero-form input {
    font-size: 21px;
}

.jobster .pxp-hero-form .mbsc-textfield-wrapper-has-icon-left input {
    padding-left: 60px;
}

.jobster .pxp-hero-form .mbsc-textfield-icon {
    color: #0969c3;
    top: 14px !important;
    left: 0px !important;
}

.dashlite .overline-main-page .overline-title-sap:before, .dashlite .overline-title-sap:after {
    width: 150px;
}

@media only screen and (min-width: 576px) {
    .main-dropdown-desktop {
        display: block;
    }
    .main-dropdown-mobile {
        display: none;
    }
    .dashlite .overline-main-page .overline-title-sap:before, .dashlite .overline-title-sap:after {
        width: 280px;
    }
    .jobster .main-page-button {
        padding: 8px 15px;
        font-size: 27px;
        line-height: 33px;
        height: auto;
        margin-top: 25px !important;
    }

    .jobster .main-page-button.pd-cst {
        padding: 15px 17px 11px 32px;
    }

    .jobster .pxp-categories-card-1-icon, .jobster .pxp-categories-card-2-icon {
        min-width: 104px;
    }

    .jobster .pxp-categories-card-1-title, .jobster .pxp-categories-card-2-title {
        margin-top: 20px;
    }

}


@media only screen and (max-width: 576px) {
    .dashlite .overline-main-page .overline-title-sap:before, .dashlite .overline-title-sap:after {
        width: 100px;
    }
    .main-dropdown-desktop {
        display: none;
    }
    .main-dropdown-mobile {
        display: block;
        font-weight: 500;
        letter-spacing: 1px;
        font-size: 21px;
        text-align: center;
        

        transition: ease-in-out max-height 400ms;
        will-change: max-height;
        transition-delay: 100ms;
        overflow: hidden;
        /* max-height: 0; */
    }
    .main-dropdown-mobile-animated {
        margin-bottom: 20px;
        /* max-height: 350px; */
    }
    .main-dropdown-mobile strong {
        font-weight: 800;
        color: var(--pxpMainColor);
    }
    .main-dropdown-mobile i {
        color: var(--pxpMainColor);
    }
    .pulse-button {
        height: 225px !important;
        padding: 10px 30px;
        height: auto !important;
    }
    .jobster .pxp-hero h1 {
        font-size: 34px;
    }
    .jobster .pxp-jobs-card-1-bottom, .jobster .pxp-jobs-card-2-bottom {
        margin-top: 32px;
    }
    .jobster .main-page-button .main-page-button-icon {
        display: none !important;
    }
    .jobster .main-page-button {
        padding: 17px 31px;
		font-size: 25px;
		line-height: 33px;
    }
    .jobster .pxp-jobs-card-1-bottom-left .main-page-button {
        padding: 8px 15px;
    }
    .popup-dropdown-custom .mbsc-popup:not(.mbsc-popup-bottom) {
        max-width: 330px !important;
        width: 90vw !important;
    }
    .popup-dropdown-custom .mbsc-popup.mbsc-popup-bottom {
        width: 100vw !important;
        border-top: 0;
    }
    .popup-dropdown-custom .mbsc-popup.mbsc-popup-bottom .mbsc-popup-body {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }
    .popup-dropdown-custom .mbsc-popup.mbsc-popup-bottom .mbsc-popup-body .mbsc-popup-button {
        font-weight: 600;
        font-size: 17px;
        margin-right: 9px;
    }

    .popup-dropdown-custom .mbsc-popup.mbsc-popup-bottom .mbsc-popup-body .mbsc-popup-button.mbsc-icon-button .mbsc-button-icon {
        font-size: 28px;
    }

    .mbsc-scroller-wheel-item.mbsc-active {
        background: none !important;
    }
    .find-jobs-button {
        font-size: 21px !important;
    }

    .jobster .pxp-categories-card-1-icon, .jobster .pxp-categories-card-2-icon {
        color: var(--pxpMainColor);
        height: 60px;
        padding: 17px 5px;
        width: 60px;
        font-size: 24px;
        line-height: 31px;
    }

    .jobster .pxp-categories-card-1-subtitle, .jobster .pxp-categories-card-1-subtitle {
        display: none;
    }

    .jobster .pxp-categories-card-1-title, .jobster .pxp-categories-card-1-title {
        line-height: 15px;
        font-size: 12px;
        font-weight: 600;
    }

    .jobster .pxp-categories-card-1-title, .jobster .pxp-categories-card-2-title {
        margin: auto;
        padding-top: 10px;
        padding-bottom: 5px;
        width: 100%;
    }

    .jobster .pxp-categories-card-2 {
        padding: 15px 5px;
    }

    .jobster .pxp-categories-card-1-container {
        margin-top: 20px;
    }

    .jobster .pxp-categories-card-1-container {
        padding: 0 10px;
    }
}




.jobster .pxp-categories-card-1-subtitle, .jobster .pxp-categories-card-2-subtitle {
    margin-top: 10px;
}

.jobster .pxp-categories-card-1-icon, .jobster .pxp-categories-card-2-icon {
    color: var(--pxpMainColor);
}