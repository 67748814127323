.mg-50 {
    margin-top: 50px;
}

.mg-55 {
    margin-top: 55px;
}

.mg-60 {
    margin-top: 60px;
}

.mg-65 {
    margin-top: 65px;
}

#search-input label {
    width: 400px;
}