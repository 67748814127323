ion-content.background-lightgrey {
	--ion-background-color: #f2f2f7;
}

ion-content.background-lightgrey {
	--ion-background-color: #fbfbfb;
}

.nk-block .heading {
	border-bottom: 1px solid #cecece;
	background: white;
    /* border-top-right-radius: 17px;
    border-top-left-radius: 17px; */
	box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
}

.nk-block .heading h3 {
	color: var(--ion-color-primary);
}

/* label.mbsc-textfield-wrapper-floating input:not(.mbsc-error) {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

label.mbsc-textfield-wrapper-box input:not(.mbsc-error) {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
} */
/* 
label.mbsc-textfield-wrapper-floating input {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
} */

label.mbsc-textfield-wrapper-floating input {
	/* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
	/* box-shadow: rgb(99 99 99 / 16%) 0px 2px 8px 0px;
	border: 1px solid #ebebeb !important; */
}

label.mbsc-textarea-wrapper {
	box-shadow: rgb(99 99 99 / 16%) 0px 2px 8px 0px;
	border: 1px solid #ebebeb !important;
	border-radius: 0.5em;
}





/* label.mbsc-textfield-wrapper-box input {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
} */

label.mbsc-textfield-wrapper-box input {
	/* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
	/* box-shadow: rgb(60 64 67 / 16%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px; */
	box-shadow: rgb(99 99 99 / 16%) 0px 2px 8px 0px;
	border: 1px solid #ebebeb !important;
}

/* .mbsc-ios.mbsc-label-floating-active.mbsc-label {
	transform: translateY(-0.6em) translateX(-0.25em) scale(.75);
} */

.mbsc-ios.mbsc-label-floating-active.mbsc-label {
	transform: translateY(-0.5em) translateX(-0.05em) scale(.75);
}

.mbsc-form-control-wrapper .mbsc-label,
.mbsc-form-control-wrapper .mbsc-label input,
.mbsc-form-control-wrapper label,
.mbsc-form-control-wrapper .mbsc-error-message,
.mbsc-form-control-wrapper input {
	font-family: 'Nunito';
}

.mbsc-ios.mbsc-textfield-wrapper-box,
.mbsc-ios.mbsc-textfield-wrapper-outline {
	margin: .3em .3em;
	margin-right: 0 !important;
	margin-left: 0 !important;
}


/* .mbsc-ios.mbsc-label-box-floating-has-icon-left.mbsc-ltr, .mbsc-ios.mbsc-label-outline-floating-has-icon-left.mbsc-ltr {
    left: 2.99em;
  } */

/* .mbsc-ios.mbsc-label-floating {
	top: .76em;
} */

.mbsc-ios.mbsc-label-floating {
	font-size: 19px;
    top: 0.62em;
}

.mbsc-textfield-icon-floating {
	width: 0.7rem;
	height: 1rem;
	font-size: 1.3rem;
	line-height: 1rem;
}

 ::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
	border-radius: 10px!important;
	background-color: #F5F5F5!important;
}

 ::-webkit-scrollbar {
	width: 6px!important;
	background-color: #F5F5F5!important;
}

 ::-webkit-scrollbar-thumb {
	border-radius: 10px!important;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)!important;
	background-color: #dadada !important;
}

div::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
	border-radius: 10px!important;
	background-color: #F5F5F5!important;
}

div::-webkit-scrollbar {
	width: 6px!important;
	background-color: #F5F5F5!important;
}

div::-webkit-scrollbar-thumb {
	border-radius: 10px!important;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)!important;
	background-color: #dadada !important;
}

.mbsc-ios.mbsc-error-message-has-icon-left.mbsc-ltr {
	padding-left: 1.333334em;
}

label.mbsc-hb {
	border-radius: 10px;
}

/* .mbsc-font {
	font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
} */

.mbsc-picker.mbsc-font {
	font-family: Roboto, sans-serif, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
}

.mbsc-popup-pointer.mbsc-picker .mbsc-popup-overlay,
.mbsc-popup-pointer .mbsc-popup-overlay-anchored {
	background: rgba(0, 0, 0, .5);
}

.ripple-effect {
	background-color: rgb(0 0 0 / 13%);
}

ion-tab-button ion-icon {
	transition: ease-in-out 75ms;
}

ion-tab-button ion-label {
	transition: ease-in-out 75ms;
}

ion-tab-button[aria-selected='true'] ion-icon {
	transform: scale(1.05);
}

ion-tab-button ion-label {
	font-size: 11px;
}

ion-tab-button[aria-selected='true'] ion-label {
	transform: scale(1.2);
}


/* ion-tab-button {
    padding-top: 5px;
    margin-bottom: 5px;
  } */

ion-tab-button ion-icon {
	padding-top: 5px;
}

ion-tab-button ion-label {
	margin-bottom: 5px;
}

ion-tab-button .button-native {
	padding: 0 !important;
}


/* ion-tab-button[aria-selected='true'] {
    background: var(--ion-color-third);
    color: white;
  } */

/* ion-ripple-effect {
	color: rgb(0 0 0 / 50%);
} */

ion-tab-bar {
	border-top: none;
	box-shadow: rgb(0 0 0 / 24%) 0px 5px 15px;
}

.mbsc-popup-arrow {
	background: var(--ion-color-primary) !important;
}

.modal-top-border .mbsc-popup-body .mbsc-popup-content .dropdown-menu {
	border: 0;
}

.modal-top-border .mbsc-popup-body .mbsc-popup-content .dropdown-menu {
	/* border-top: solid 3px var(--ion-color-primary); */
	margin: 0;
}

.dropdown-menu {
	border-top: none !important;
}

ion-tab-bar {
	/* padding-top: 5px;
    padding-bottom: 4px; */
	height: 54px;
}

.mbsc-popup button {
	font-family: 'Nunito';
}

.fs-15 {
	font-size: 15px;
}

.fs-16 {
	font-size: 16px;
}

.fs-17 {
	font-size: 17px;
}

.no-padding {
	padding: 0 !important;
}

.no-padding-label label {
	padding: 0 !important;
}

.no-margin-label label {
	margin: 0 !important;
}

.no-margin-l-r-label label {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.btn {
	box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px !important;
}

.input-PESEL input {
	font-weight: 600;
	letter-spacing: 10px;
}

.mbsc-ios.mbsc-textfield-icon-right {
	right: 1.45em;
}

@keyframes slide-up {
	0% {
		opacity: 0;
		transform: translateY(20px)
	}
	to {
		opacity: 1;
		transform: translateY(0)
	}
}

.error-border {
	border: 1px solid red !important;
}


/* ion-content {
    --keyboard-offset: 0px !important;
    --offset-top: 0px !important;
    --offset-bottom: 0px !important;
    min-height: 100%;
  } */

.loader-container {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: white;
	opacity: 1;
	transition: ease-in-out 200ms all;
	pointer-events: none;
	z-index: 1;
}

.loader-container.hide {
	opacity: 0;
}

.loader-container .loader {
	position: absolute;
    top: calc( 50% - 45px );
    width: 100%;
}

.loader-container .loader ion-icon {
	animation: up-down 500ms ease-in-out infinite alternate-reverse both;
	width: 75%;
    height: 70px;
    max-width: 370px;
    margin: auto;
    display: block;
}

@keyframes up-down {
	0% {
		transform: scale(1)
	}
	to {
		transform: scale(1.05)
	}
}

a {
	cursor: pointer;
}

.mbsc-popup-wrapper:not(.mbsc-picker):not(.mbsc-popup-wrapper-bottom) .mbsc-popup-body {
	border-top: solid 3px var(--ion-color-primary) !important;
}

.link-list-opt.no-bdr {
	background: white;
}

.btn-select {
	height: 59px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	margin-top: auto;
	margin-bottom: auto;
}

.form-wrap-select-button-aside {
	display: inline-flex !important;
}

.form-wrap-select-button-aside label {
	margin-right: 0 !important;
	padding-right: 0 !important;
}

.form-wrap-select-button-aside input {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.form-wrap-select-button-aside button {
	border-top-left-radius: 0 !important;
	border-bottom-left-radius: 0 !important;
	height: 58px;
	margin-top: auto;
	margin-bottom: auto;
}

ion-skeleton-text {
	margin: 0;
}

.h-14 {
	height: 14px;
}

.h-19 {
	height: 19px;
}

.h-40 {
	height: 40px;
}

ion-sjeion-skeleton-text.circle {
    border-radius: 50px;
}

.remove-background {
    background: none !important;
}

#list {
    scroll-margin: 55px;
}


.input-bold input {
	font-weight: 400;
    /* letter-spacing: 1px; */
}

.input-primary input {
	font-weight: 600;
    letter-spacing: 1px;
}


.input-primary {
	margin-right: 0 !important;
    margin-left: 0 !important;
}

.mbsc-ios.mbsc-textfield-box.mbsc-error, .mbsc-ios.mbsc-textfield-outline.mbsc-error {
    /* border-color: transparent; */
	/* box-shadow: rgb(239 62 62 / 48%) 0px 0px 2px 1px, rgb(239 62 62 / 30%) 0px 1px 3px 0px; */
}

.mbsc-error-message-box {
	background: #ce3b3b;
    color: white !important;
    font-weight: 600 !important;
    border-bottom-left-radius: 6px;
    margin-right: 0 !important;
    font-size: 9px !important;
    right: 0;
	box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
    letter-spacing: 1px;
    padding: 0 7px !important;
	pointer-events: none;
}



.mbsc-label-box-floating {
	/* font-size: 17px;
    top: 13px !important; */
    letter-spacing: 0.4px;
}

.color-primary {
	color: var(--ion-color-primary);
}

input {
	font-weight: 600;
	letter-spacing: 0.4px;
}

.mbsc-select-filter-clear {
	margin-right: 12px;
}

@keyframes error-animation {
	from 
	{
		max-height: 0;
	}
  
	to 
	{
		max-height: 21px;
	}
}

.mbsc-ios.mbsc-textfield-floating, .mbsc-ios.mbsc-textfield-stacked {
	/* padding: 0 1.25em; */
    padding-top: 1em;
	font-size: 18px;
	height: 3.4em;
	font-weight: 600;
}

.mbsc-textfield-tags-inner .mbsc-ios.mbsc-textfield-floating, .mbsc-textfield-tags-inner .mbsc-ios.mbsc-textfield-stacked {
	padding-top: 0;
}

/* .mbsc-textfield {
	font-size: 1.05rem;
} */

.card.card-preview {
	box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
	background: none;
}

.card.card-preview .card-inner {
	background: white;
	box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
}

.mbsc-ios.mbsc-select-icon-floating, .mbsc-ios.mbsc-select-icon-stacked {
	top: 1.15em;
}

.mbsc-textfield-icon-left {
	font-size: 30px;
    left: 15px !important;
    top: 20px !important; 
}

.mbsc-textfield-floating-active + .mbsc-textfield-icon-left {
	color: var(--ion-color-primary);
}

.shadow-primary {
	box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
}

.center-vertically {
	margin-top: auto;
	margin-bottom: auto;
}

.mbsc-ios.mbsc-form-control-wrapper:after, .mbsc-ios.mbsc-form-control-wrapper:before {
    border: 0;
}

.mbsc-switch {
	margin-right: 5px;
    margin-left: 5px;
    box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
    border-radius: 7px;
}

textarea {
	min-height: 80px;
}


/* label.mbsc-focus {
	font-weight: 600;
} */

.nk-ibx-menu .active li {
    background: #eff1ff;
    border-radius: 3px;
}

.mbsc-ios.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-bar-none .mbsc-scroller-wheel-item.mbsc-ltr {
	font-size: 17px;
}

.mbsc-ios.mbsc-select-filter-input {
    height: 2.5rem;
    font-size: 18px;
}

.mbsc-ios.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected {
    /* color: white;
    background: var(--ion-color-primary); */
	color: var(--ion-color-primary);
}

.mg-auto-center {
	margin: 0 auto;
}

.card-preview {
    border: none;
}

.mbsc-ios.mbsc-textarea {
	border-radius: 0.5em;
	border: 1px solid transparent;
}

.mbsc-optional-message-box {
    background: var(--ion-color-third);
    color: white !important;
    font-weight: 600 !important;
    border-bottom-left-radius: 6px;
	border-top-right-radius: 6px;
    margin-right: 0 !important;
    font-size: 11px !important;
    right: 5px;
    box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
    letter-spacing: 1px;
    padding: 0 7px !important;
	z-index: 3;
	top: 0;
	pointer-events: none;
}


ion-content {
	--offset-bottom: 0px !important;
	--keyboard-offset: 0px !important
}


input {
	scroll-margin: 140px;
}

textarea {
	scroll-margin: 140px;
}


html {
	/* --ion-default-font: 'Poppins',-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important; */
	--ion-default-font: 'Nunito' !important;
}

.pxp-hero-boxed-info-card-small-title {
	width: 210px;
}

label.mbsc-textfield-wrapper-box .remove-shadow {
	box-shadow: none !important;
	border: unset !important;
}

.mbsc-ios.postcode-main .mbsc-label {
	font-size: 21px !important;
}

.mbsc-ios.postcode-main input {
	font-size: 23px !important;
	height: 3.4em !important;
}
/* 
.mbsc-ios.mbsc-label:not(.mbsc-label-floating-active) {
	font-weight: 700;
} */

.mbsc-form-control-wrapper .mbsc-label, .mbsc-form-control-wrapper .mbsc-label input, .mbsc-form-control-wrapper label, .mbsc-form-control-wrapper .mbsc-error-message, .mbsc-form-control-wrapper input {
    --ion-default-font: 'Poppins',-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}

.jobster .pxp-hero-boxed-info-card-small {
    max-width: 330px;
}

.jobster .pxp-hero-form-round form button.btn-success {
    background-color: #1ee0ac;
	border-color: #1ee0ac;
}

.jobster .pxp-hero-form-round form button.btn-success:hover {
    background-color: #19be92;
}

.jobster .btn-primary:focus {
	background: var(--ion-color-primary-gradient) !important;
}

.jobster .btn-primary:hover {
	background: var(--ion-color-primary-gradient) !important;
}

button {
	overflow: hidden !important;
}

button .btn-secondary {
	background-color: var(--ion-color-secondary);
}

.jobster .btn-primary:focus, .jobster .btn-primary:hover {
	background: var(--ion-color-primary-gradient) !important;
}

.jobster .btn-primary {
	background: var(--ion-color-primary-gradient) !important;
}

.hidden {
	display: none !important;
}



.mbsc-switch {
	box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
}

.custom-alert-class .alert-message {
	font-size: 17px;
    font-family: 'Nunito';
    font-weight: 400;
	max-height: none;
}

.custom-alert-class h2 {
	font-size: 20px !important;
}

ion-router-link {
	cursor: pointer;
}


.pxp-jobs-card-1-company {
	margin-top: 0 !important;
}

/* .mbsc-textfield-inner.mbsc-textfield-inner-box.mbsc-textfield-inner-floating:not(.mbsc-textarea-inner)::after {
	content: '';
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 5px;
    box-shadow: rgb(60 64 67 / 5%) 0px 1px 2px 0px, rgb(60 64 67 / 10%) 0px 2px 6px 2px;
    transition: opacity 0.2s ease-in-out;
	pointer-events: none;
}

.mbsc-textfield-inner.mbsc-textfield-inner-box.mbsc-textfield-inner-floating.mbsc-focus:not(.mbsc-textarea-inner)::after {
	opacity: 1;
} */

.mbsc-textfield-inner.mbsc-textfield-inner-box.mbsc-textfield-inner-floating:not(.mbsc-textarea-inner) {
	display: flex;
}

.jobster .pxp-nav > ul > li > a {
    font-size: 17px;
}



.jobster a:hover {
    color: unset
}

ion-ripple-effect.ripple-small  {
	color: rgba(0, 0, 0, 0.35);
}

textarea {
	font-family: 'Nunito' !important;
}

.ion-page {
	box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
	will-change: transform, border-radius;
}