.pin-container {
    display: inline-flex;
}

.pin-container label {
    width: 27%;
}

.pin-sep {
    width: 10%;
    position: relative;
    margin: 0 7px;
}

.pin-sep-line {
    width: 100%;
    height: 8px;
    background: white;
    position: absolute;
    bottom: calc( 50% - 3px );
    border-radius: 8px;
    box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
}


.pin-container label {
    margin-right: 3px !important;
    margin-left: 3px !important;
}

.pin-container input {
    padding: 0 !important;
    height: 60px !important;
    box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
    font-size: 37px;
    font-weight: 600;
    font-family: 'Nunito';
    border-radius: 8px !important;
    color: #6576ff !important;
    caret-color: white;
}

.pin-container input[disabled]
{
    pointer-events: none;
}

.pin-container input:focus {
    border: 1px solid #6576ff
}

@media only screen and (min-width: 576px) {
    .pin-container {
        margin: 0 7px;
    }
    .pin-container input {
        text-align: right;
    }
  }
  
@media only screen and (max-width: 576px)  {
    .pin-container {
        margin: 0;
    }
    .pin-container input {
        text-align: center;
    }
}

@media only screen and (max-width: 321px)  {
    .pin-sep {
        width: 11% !important;
    }
}




.test {
    color: rgba(101, 115, 225, 0) !important;
    transition: ease-in-out 300ms all;
    padding-top: 23px !important;
}

.test-animate {
    color: rgba(101, 115, 225, 1) !important;
    padding-top: 0 !important;
}