/* .jobster .pxp-dashboard-content-details textarea {
    height: 600px;
} */




@media only screen and (max-width: 576px) {

    .add-job-container textarea {
        min-height: 300px;
    }

}

@media only screen and (min-width: 576px) {

    .add-job-container textarea {
        min-height: 570px;
    }

}

.jobster .pxp-dashboard-content-details {
    min-height: unset;
}