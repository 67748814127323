.pxp-page-header-simple {
    margin-top: 0 !important;
}



@media only screen and (min-width: 576px) {
    section.jobs {
        margin-top: 70px;
    }
}

@media only screen and (max-width: 576px) {
    section.jobs {
        margin-top: 25px;
    }
    .jobster .pxp-page-header-simple {
        padding: 30px 0;
    }
    .jobster .pxp-page-header-simple {
        padding: 0 !important;
        background: none !important;
    }
    
    .jobster .pxp-hero-form.pxp-hero-form-round.pxp-bigger {
        /* margin-top: 0 !important; */
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
    }

    .jobster .pxp-jobs-card-1-details, .jobster .pxp-jobs-card-2-details, .jobster .pxp-jobs-card-3-details {
        margin-top: 0;
    }

    .jobster .pxp-jobs-card-1-bottom, .jobster .pxp-jobs-card-2-bottom {
        margin-top: 10px;
    }
}