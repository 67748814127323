.jobster {

/*
Template Name: Jobster
Template URI: http://pixelprime.co/themes/jobster
Description: Job Board HTML Template
Version: 1.0
Author: Pixel Prime
Author URI: https://pixelprime.co/
*/

/**
 * Table of Contents
 *
 * 1.0 - General
 * 2.0 - Header
 * 3.0 - Navigation
 * 4.0 - Heroes
 * 5.0 - Categories section
 * 6.0 - Jobs section
 * 7.0 - Promo image section
 * 8.0 - Companies section
 * 9.0 - Services section
 * 10.0 - Info section
 * 11.0 - Testimonials section
 * 12.0 - Posts section
 * 13.0 - Cities section
 * 14.0 - Candidates section
 * 15.0 - Price plans section
 * 16.0 - Subscribe section
 * 17.0 - Lists section
 * 18.0 - Jobs list
 * 19.0 - Single job
 * 20.0 - Companies list
 * 21.0 - Single company
 * 22.0 - Company dashboard
 * 23.0 - Candidates list
 * 24.0 - Single candidate
 * 25.0 - Candidate dashboard
 * 26.0 - Blog
 * 27.0 - FAQs
 * 28.0 - Contact
 * 29.0 - Sign in / Sign Uup
 * 30.0 - Modals
 * 31.0 - Footer
 * 32.0 - Media queries
 */

/**
 * 1.0 - General
 */

body {
    font-family: 'Poppins',-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    color: var(--pxpTextColor);
    font-weight: 300;
    font-feature-settings: 'kern';
    -moz-font-feature-settings: 'kern';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} 
a {
    color: var(--pxpMainColor);
    text-decoration: none;
}
a:hover {
    color: var(--pxpMainColorDark);
}
.pxp-preloader {
    background-color: #fff;
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.pxp-container {
    width: 100%;
    padding-right: .75rem;
    padding-left: .75rem;
    margin-right: auto;
    margin-left: auto;
}
.pxp-pl-80 {
    padding-left: 80px;
}
.pxp-cover {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}
.pxp-cover-bottom {
    background-position: center bottom;
}
.pxp-cover-top {
    background-position: center top;
}
.mt-100 {
    margin-top: 100px;
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.pxp-text-right {
    text-align: right;
}
.pxp-text-light {
    opacity: .7;
}
.pxp-section-h2 {
    font-weight: 700;
    font-size: 48px;
    letter-spacing: -2px;
}
.pxp-animate-in.pxp-animate-in-top {
    transform: translateY(20px);
    opacity: 0;
    transition: 1s cubic-bezier(0.215,0.61,0.355,1);
    transition-property: transform, opacity;
}
.pxp-animate-in.pxp-animate-in-top.pxp-in {
    transform: translateY(0);
    opacity: 1;
}
.pxp-animate-in.pxp-animate-in-right {
    transform: translateX(-20px);
    opacity: 0;
    transition: 1s cubic-bezier(0.215,0.61,0.355,1);
    transition-property: transform, opacity;
}
.pxp-animate-in.pxp-animate-in-right.pxp-in {
    transform: translateX(0);
    opacity: 1;
}
.pxp-section-cta,
.pxp-section-cta-o {
    background-color: var(--pxpMainColor);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 10px 20px;
    transition: var(--pxpHoverTransition);
    transition-property: background-color;
}
.pxp-section-cta-o {
    background-color: transparent;
    color: var(--pxpMainColor);
    padding: 0;
    transition-property: color;
}
.pxp-section-cta:hover {
    color: #fff;
    background-color: var(--pxpMainColorDark);
}
.pxp-section-cta-o:hover {
    color: var(--pxpMainColorDark);
    background-color: transparent;
}
.pxp-section-cta span,
.pxp-section-cta-o span {
    margin-left: 10px;
    transition: var(--pxpHoverTransition);
    transition-property: margin-left;
}
.pxp-section-cta:hover span,
.pxp-section-cta-o:hover span {
    margin-left: 20px;
}
.pxp-subsection-cta {
    background-color: var(--pxpMainColorLight);
    color: var(--pxpMainColorDark);
    font-size: 18px;
    font-weight: 500;
    padding: 10px 20px;
    transition: var(--pxpHoverTransition);
    transition-property: background-color, color;
}
.pxp-subsection-cta:hover {
    color: #fff;
    background-color: var(--pxpMainColor);
}
.pxp-404-fig img {
    width: 360px;
    height: auto;
}

/**
 * 2.0 - Header
 */

.pxp-header {
    background-color: transparent;
    transition: 300ms cubic-bezier(0.215,0.61,0.355,1);
    transition-property: background-color, box-shadow;
}
.pxp-header.pxp-has-border {
    border-bottom: 1px solid var(--pxpLightGrayColor);
}
.pxp-header.pxp-is-sticky {
    background-color: #fff;
    box-shadow: 0px 15px 30px rgba(0,0,0,0.16);
}
.pxp-header.pxp-is-sticky.pxp-has-border  {
    border-bottom: 0 none;
}
.pxp-header-container {
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: space-between;
    flex-wrap: wrap;
}
.pxp-header.pxp-bigger .pxp-header-container {
    height: 120px;
    transition: 300ms cubic-bezier(0.215,0.61,0.355,1);
    transition-property: height;
}
.pxp-header.pxp-bigger.pxp-is-sticky .pxp-header-container {
    height: 80px;
}
.pxp-logo > a {
    color: var(--pxpTextColor);
    text-decoration: none;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -1px;
    transition: var(--pxpHoverTransition);
    transition-property: opacity;
}
.pxp-logo.pxp-light > a {
    color: #fff;
}
.pxp-header.pxp-is-sticky .pxp-logo.pxp-light > a {
    color: var(--pxpTextColor);
}
.pxp-logo > a:hover {
    opacity: var(--pxpHoverOpacity);
}
.pxp-logo-nav-container {
    display: flex;
    align-items: center;
}
.pxp-search-container {
    width: 40%;
}
.btn-check:focus + .btn, 
.btn:focus {
    box-shadow: none;
}

/**
 * 3.0 - Navigation
 */

.pxp-logo-nav-container .pxp-nav {
    margin-left: 40px;
}
.pxp-nav > ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    align-items: center;
}
.pxp-nav > ul > li > a {
    padding: 10px 20px;
    font-weight: 500;
    font-size: 15px;
    color: var(--pxpTextColor);
    text-decoration: none;
    transition: var(--pxpHoverTransition);
    transition-property: color, opacity;
}
.pxp-nav > ul > li > a::after {
    content: none;
}
.pxp-nav.pxp-light > ul > li > a {
    color: #fff;
}
.pxp-header.pxp-is-sticky .pxp-nav.pxp-light > ul > li > a {
    color: var(--pxpTextColor);
}
.pxp-nav > ul > li > a:hover {
    opacity: var(--pxpHoverOpacity);
}
.pxp-nav a:focus {
    outline: 0;
}
.pxp-nav .dropdown-menu {
    margin-top: -1px !important;
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0px 15px 30px rgba(0,0,0,0.10);
    padding: 30px;
}
.pxp-nav > ul > li > ul a {
    font-size: 14px;
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: color, opacity;
    padding: 7px 0;
    color: var(--pxpTextColor);
}
.pxp-nav > ul > li > ul a:hover,
.pxp-nav > ul > li > ul a:focus {
    background-color: transparent;
}
.pxp-nav > ul > li > ul a:hover {
    opacity: var(--pxpHoverOpacity);
}
.pxp-nav > ul > li > ul a.dropdown-toggle {
    display: flex;
    justify-content: space-between;
}
.pxp-nav > ul > li > ul a.dropdown-toggle::after {
    transition: var(--pxpHoverTransition);
    transition-property: transform;
    margin-left: 7px;
    height: .3em;
    margin-top: 6px;
}
.pxp-user-nav {
    display: flex;
    align-items: center;
}
.pxp-header-right .pxp-user-nav {
    margin-left: 40px;
}
.pxp-nav-btn {
    background-color: var(--pxpMainColor);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: color, background-color;
}
.pxp-nav-btn:hover {
    color: var(--pxpMainColorDark);
    background-color: #fff;
}
.pxp-is-sticky .pxp-nav-btn:hover, 
.pxp-on-light .pxp-nav-btn:hover {
    color: #fff;
    background-color: var(--pxpMainColorDark);
}
.pxp-user-nav-trigger {
    margin-left: 10px;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid rgba(255,255,255,.5);
    color: #fff;
    background-color: transparent;
    transition: var(--pxpHoverTransition);
    transition-property: color, background-color;
}
.pxp-is-sticky .pxp-user-nav-trigger,
.pxp-on-light .pxp-user-nav-trigger {
    color: var(--pxpTextColor);
    border: 1px solid var(--pxpTextColor);
}
.pxp-user-nav-trigger:hover {
    background-color: #fff;
    color: var(--pxpMainColorDark);
}
.pxp-is-sticky .pxp-user-nav-trigger:hover,
.pxp-on-light .pxp-user-nav-trigger:hover {
    background-color: var(--pxpTextColor);
    color: #fff;
}
.pxp-nav-trigger {
    margin-left: 40px;
}
.pxp-nav-trigger.pxp-is-dashboard {
    margin-left: 0;
    display: inline-block;
}
.pxp-nav-trigger > a {
    display: block;
}
.pxp-nav-trigger > a > div {
    height: 3px;
    background-color: var(--pxpTextColor);
    border-radius: 2px;
    transition: var(--pxpHoverTransition);
    transition-property: opacity;
}
.pxp-nav-trigger.pxp-light > a > div {
    background-color: #fff;
}
.pxp-nav-trigger.pxp-light > a > div {
    background-color: #fff;
}
.pxp-header.pxp-is-sticky .pxp-nav-trigger.pxp-light > a > div {
    background-color: var(--pxpTextColor);
}
.pxp-nav-trigger > a:hover > div {
    opacity: var(--pxpHoverOpacity);
}
.pxp-nav-trigger > a > .pxp-line-1 {
    width: 20px;
}
.pxp-nav-trigger > a > .pxp-line-2 {
    width: 26px;
    margin-top: 5px;
}
.pxp-nav-trigger > a > .pxp-line-3 {
    width: 14px;
    margin-top: 5px;
}
.pxp-header-right {
    display: flex;
    align-items: center;
}
.pxp-user-nav-avatar {
    width: 36px;
    height: 36px;
    border-radius: 18px;
}
.pxp-user-nav-dropdown > .dropdown-toggle {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    color: var(--pxpTextColor);
}
.pxp-user-nav-dropdown > .dropdown-toggle::after {
    margin-left: 7px;
    border-top-color: #fff;
}
.pxp-is-sticky .pxp-user-nav-dropdown > .dropdown-toggle::after, 
.pxp-on-light .pxp-user-nav-dropdown > .dropdown-toggle::after {
    border-top-color: var(--pxpTextColor);
}
.pxp-user-nav-dropdown {
    margin-left: 10px;
}
.pxp-user-nav-name {
    margin-left: 7px;
    transition: var(--pxpHoverTransition);
    transition-property: opacity;
    color: #fff;
}
.pxp-is-sticky .pxp-user-nav-name, 
.pxp-on-light .pxp-user-nav-name {
    color: var(--pxpTextColor);
}
.pxp-user-nav-dropdown > .dropdown-toggle:hover .pxp-user-nav-name {
    opacity: .7;
}
.pxp-user-nav-dropdown .dropdown-menu {
    margin-top: 7px !important;
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0px 15px 30px rgba(0,0,0,0.10);
}
.pxp-user-nav-dropdown ul.dropdown-menu > li > a {
    font-size: 14px;
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: color, opacity;
    padding: 7px 20px;
    color: var(--pxpTextColor);
}
.pxp-user-nav-dropdown ul.dropdown-menu > li > a:hover,
.pxp-user-nav-dropdown ul.dropdown-menu > li > a:focus {
    background-color: transparent;
    opacity: var(--pxpHoverOpacity);
}
.pxp-dropdown-lists {
    flex-wrap: nowrap;
}
.pxp-dropdown-list {
    white-space: nowrap;
}
.pxp-dropdown-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.pxp-dropdown-header {
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 15px;
    opacity: .7;
}
.pxp-dropdown-header {
    white-space: nowrap;
}
.pxp-nav > ul > li > ul .pxp-dropdown-body a {
    padding: 7px 0;
}
.pxp-dropdown-body a.pxp-has-icon {
    display: flex;
    align-items: center;
}
.pxp-nav a.pxp-has-icon-small {
    display: flex;
    align-items: flex-start;
}
.pxp-dropdown-icon {
    margin-right: 10px;
    width: 32px;
    height: 32px;
    flex: 0;
}
.pxp-has-icon-small .pxp-dropdown-icon {
    width: auto;
}
.pxp-dropdown-icon img {
    width: auto;
    height: 32px;
}
.pxp-dropdown-icon span {
    font-size: 16px;
    opacity: .7;
    color: var(--pxpMainColorDark);
    margin-top: 2px;
}
.pxp-dropdown-text {
    flex: 1;
}
.pxp-dropdown-text > span {
    display: block;
    font-weight: 300;
    opacity: .7;
    font-size: 13px;
}
.pxp-nav-mobile-container.offcanvas {
    border-right: 0 none;
    box-shadow: 15px 0 30px rgba(0,0,0,0.16);
}
.pxp-nav-mobile-container.pxp-is-dashboard.offcanvas {
    background-color: var(--pxpMainColorLight);
}
.pxp-nav-mobile-container.pxp-is-dashboard.pxp-is-candidate.offcanvas {
    background-color: var(--pxpSecondaryColorLight);
}
.pxp-nav-mobile-container .offcanvas-header {
    padding: 30px;
}
.pxp-nav-mobile-container .offcanvas-body {
    padding: 30px;
}
.pxp-nav-mobile-container .offcanvas-header .btn-close {
    transition: var(--pxpHoverTransition);
    transition-property: opacity;
}
.pxp-nav-mobile .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pxp-nav-mobile .dropdown-toggle::after {
    transition: var(--pxpHoverTransition);
    transition-property: transform;
    margin-left: 0;
    height: .3em;
}
.pxp-nav-mobile a {
    color: var(--pxpTextColor);
    text-decoration: none;
    transition: var(--pxpHoverTransition);
    transition-property: opacity;
}
.pxp-nav-mobile a:hover {
    opacity: .7;
    color: var(--pxpTextColor);
}
.pxp-nav-mobile > ul > li > a {
    font-weight: 600;
    font-size: 18px;
}
.pxp-nav-mobile .navbar-nav .dropdown-menu {
    width: 100%;
    border: 0 none;
}
.pxp-nav-mobile-container.pxp-is-dashboard .pxp-nav-mobile .navbar-nav .dropdown-menu {
    background-color: var(--pxpMainColorLight);
}
.pxp-nav-mobile > ul > li ul.dropdown-menu li:not(.pxp-dropdown-header) {
    padding-left: 10px;
}
.pxp-nav-mobile > ul > li ul.dropdown-menu li a {
    font-size: 15px;
    font-weight: 500;
    padding: 7px 0;
}
.pxp-nav-mobile > ul > li ul.dropdown-menu li a:not(.dropdown-toggle) {
    display: block;
}
.pxp-nav-mobile .pxp-dropdown-header {
    font-size: 16px;
    margin-top: 20px;
}

/**
 * 4.0 - Heroes
 */

.pxp-hero {
    position: relative;
    overflow: hidden;
    min-height: 922px;
}
.pxp-hero-right-bg-card {
    background-color: var(--pxpMainColorDark);
    position: absolute;
    right: 0;
    border-radius: 200px;
    width: 50%;
    height: 150%;
    bottom: 200px;
    transform: rotate(32deg);
    transform-origin: bottom left;
}
.pxp-hero-right-bg-card.pxp-with-image {
    overflow: hidden;
    background-color: transparent;
}
.pxp-hero-right-bg-card.pxp-with-image::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 28%;
    left: -9%;
    z-index: -1;
    background: var(--backgroundImage);
    transform: rotate(-32deg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left bottom;
}
.pxp-hero-right-bg-card.pxp-has-animation {
    transform: rotate(32deg) translateX(50px);
    opacity: 0;
    transition: .4s cubic-bezier(0.215,0.61,0.355,1);
    transition-property: transform, opacity;
}
.pxp-hero-right-bg-card.pxp-has-animation.pxp-animate {
    opacity: 1;
    transform: rotate(32deg) translateX(0);
}
.pxp-hero-caption {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}
.pxp-hero h1 {
    font-size: 76px;
    line-height: 84px;
    letter-spacing: -3px;
    font-weight: 700;
}
.pxp-hero-subtitle {
    font-size: 18px;
}
.pxp-hero-form-round {
    background-color: #fff;
    box-shadow: 0px 6px 12px rgba(0,0,0,0.05);
    height: 80px;
    border-radius: 40px;
    padding-left: 20px;
}
.pxp-hero-form-round.pxp-has-border {
    box-shadow: none;
    border: 1px solid var(--pxpLightGrayColor);
}
.pxp-hero-form-round.pxp-has-bg {
    box-shadow: none;
    background-color: var(--pxpMainColorLight);
}
.pxp-hero-form-round.pxp-bigger {
    height: 100px;
    border-radius: 30px;
}
.pxp-hero-form-round.pxp-smaller {
    height: 60px;
}
.pxp-header.pxp-is-sticky .pxp-hero-form-round.pxp-smaller {
    box-shadow: none;
    border: 1px solid var(--pxpLightGrayColor);
}
.pxp-hero-form-round form {
    height: 80px;
}
.pxp-hero-form-round.pxp-bigger form {
    height: 100px;
}
.pxp-hero-form-round.pxp-smaller form {
    height: 60px;
}
.pxp-hero-form-round .form-floating > label {
    font-weight: 500;
}
.pxp-hero-form-round form button {
    width: 54px;
    height: 54px;
    border: 0 none;
    margin-right: 13px;
    border-radius: 27px;
    color: #fff;
    // background-color: var(--pxpMainColor);
    transition: var(--pxpHoverTransition);
    transition-property: background-color;
}
.pxp-hero-form-round.pxp-bigger form button {
    height: 66px;
    width: auto;
    border-radius: 20px;
    margin-right: 20px;
    padding-left: 20px;
    padding-right: 20px;
}
.pxp-hero-form-round.pxp-large form button {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
}
.pxp-hero-form-round.pxp-smaller form button {
    width: 48px;
    height: 48px;
    margin-right: 8px;
}
.pxp-header.pxp-is-sticky .pxp-hero-form-round.pxp-smaller form button {
    margin-top: -2px;
    margin-right: 7px;
}
.pxp-hero-form-round form button:hover {
    background-color: var(--pxpMainColorDark);
}
.pxp-hero-form-round form .pxp-has-left-border {
    border-left: 1px solid var(--pxpLightGrayColor);
}
.pxp-hero-form-round.pxp-has-bg form .pxp-has-left-border {
    border-left: 1px solid #fff;
}
.pxp-hero-form-round form .form-control,
.pxp-hero-form-round form .form-select {
    border: 0 none;
    font-weight: 300;
    color: var(--pxpTextColor);
    background-color: transparent;
}
.pxp-hero-form-round form .form-control:focus,
.pxp-hero-form-round form .form-select:focus {
    box-shadow: none;
}
.pxp-hero-form .input-group .input-group-text {
    padding-right: 0;
    color: var(--pxpTextColor);
    border: 0 none;
    background-color: transparent;
    opacity: .7;
}
.pxp-hero-form .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 1px;
}
.pxp-hero-searches-container {
    margin-top: 60px;
}
.pxp-hero-searches-container.pxp-smaller {
    width: 80%;
}
.pxp-hero-searches-label {
    font-weight: 600;
}
.pxp-hero-searches-items > a {
    background-color: var(--pxpMainColorTransparent);
    color: var(--pxpMainColor);
    font-size: 13px;
    display: inline-block;
    border-radius: 50px;
    padding: 7px 17px;
    text-decoration: none;
    margin-right: 5px;
    margin-bottom: 10px;
    transition: var(--pxpHoverTransition);
    transition-property: background-color, color;
}
.pxp-hero-searches-items > a:hover {
    background-color: var(--pxpMainColor);
    color: #fff;
}
.pxp-hero-searches-items {
    margin-top: 10px;
}
.pxp-hero-logos-carousel-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 80px;
    z-index: 3;
}
.pxp-hero-logos-carousel {
    margin-top: 60px;
}
.pxp-hero-logos-carousel.owl-carousel .owl-stage {
    display: flex;
    align-items: center;
}
.pxp-hero-cards-container {
    position: relative;
}
.pxp-hero-card {
    position: relative;
    width: 70%;
    height: 64vh;
    min-height: 560px;
    background-color: black;
    border-radius: 40px;
    z-index: 5;
}
.pxp-animate-cards .pxp-hero-card {
    transition: .6s cubic-bezier(0.215,0.61,0.355,1);
    transition-property: opacity, transform;
    transform: translateX(50px);
    opacity: 0;
}
.pxp-animate-cards .pxp-hero-card.pxp-animate {
    transform: translateX(0);
    opacity: 1;
}
.pxp-hero-card-dark {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 64vh;
    min-height: 560px;
    background-color: var(--pxpMainColor);
    z-index: 4;
    transform: rotate(7deg);
    border-radius: 40px;
    transform-origin: bottom left;
}
.pxp-animate-cards .pxp-hero-card-dark {
    transition: .6s cubic-bezier(0.215,0.61,0.355,1);
    transition-property: transform;
    transform: rotate(0);
    top: 0;
    left: 0;
    opacity: 0;
}
.pxp-animate-cards .pxp-hero-card-dark.pxp-animate {
    transform: rotate(7deg);
    opacity: 1;
}
.pxp-hero-card-light {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 64vh;
    min-height: 560px;
    background-color: var(--pxpMainColorLight);
    z-index: 3;
    transform: rotate(14deg);
    border-radius: 40px;
    transform-origin: bottom left;
}
.pxp-animate-cards .pxp-hero-card-light {
    transition: .6s cubic-bezier(0.215,0.61,0.355,1);
    transition-property: transform;
    transform: rotate(0);
    top: 0;
    left: 0;
    opacity: 0;
}
.pxp-animate-cards .pxp-hero-card-light.pxp-animate {
    transform: rotate(14deg);
    opacity: 1;
}
.pxp-hero-card-info-container {
    position: absolute;
    right: 0;
    top: 40%;
    z-index: 6;
}
.pxp-hero-card-info {
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 15px 30px rgba(0,0,0,0.2);
    overflow: hidden;
    max-height: 255px;
    padding: 15px 0;
    width: 270px;
    height: 255px;
    opacity: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.pxp-hero-card-info.pxp-animate-bounce {
    opacity: 0;
}
.pxp-hero-card-info.pxp-animate-bounce.animate__animated {
    opacity: 1;
}
.pxp-hero-card-info-item {
    display: block;
    padding: 15px 0;
    position: absolute;
    opacity: 0;
    left: 30px;
    right: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    will-change: transform, opacity;
    animation: carousel-animate-vertical 15s linear infinite;
}
.pxp-hero-card-info-item:nth-child(1) {
    animation-delay: calc(3s * -1);
}
.pxp-hero-card-info-item:nth-child(2) {
    animation-delay: calc(3s * 0);
}
.pxp-hero-card-info-item:nth-child(3) {
    animation-delay: calc(3s * 1);
}
.pxp-hero-card-info-item:nth-child(4) {
    animation-delay: calc(3s * 2);
}
.pxp-hero-card-info-item:last-child {
    animation-delay: calc(-3s * 2);
}
@keyframes carousel-animate-vertical {
    0% {
      transform: translateY(200%);
      opacity: 0;
      visibility: hidden;
    }
    3%, 20% {
      transform: translateY(100%);
      opacity: 1;
      visibility: visible;
    }
    23%, 40% {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
    43%, 60% {
      transform: translateY(-100%);
      opacity: 1;
      visibility: visible;
    }
    63% {
      transform: translateY(-200%);
      opacity: 0;
      visibility: visible;
    }
    100% {
      transform: translateY(-300%);
      opacity: 0;
      visibility: hidden;
    }
}
.pxp-hero-card-info-item-number {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    white-space: nowrap; 
}
.pxp-hero-card-info-item-number > span {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}
.pxp-hero-card-info-item-description {
    font-size: 13px;
    line-height: 13px;
    opacity: .7;
    white-space: nowrap;
}
.pxp-hero-center-carousel {
    position: absolute;
    width: 36%;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    bottom: 18%;
    overflow: hidden;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: var(--pxpLightGrayColor);
}
.pxp-hero-center-carousel.pxp-has-animation {
    opacity: 0;
    transition: 1s cubic-bezier(0.215,0.61,0.355,1);
    transition-property: transform, opacity;
    transform: translate(-50%, -20px);
}
.pxp-hero-center-carousel.pxp-has-animation.pxp-animate {
    opacity: 1;
    transform: translate(-50%, 0);
}
.pxp-hero-center-carousel .carousel,
.pxp-hero-center-carousel .carousel-inner,
.pxp-hero-center-carousel .carousel-item {
    height: 100%;
}
.pxp-hero-stats-item {
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
    border-radius: 30px;
    overflow: hidden;
    padding: 30px;
    margin-top: 10px;
    position: absolute;
    min-width: 260px;
}
.pxp-hero-stats-item.pxp-has-animation {
    opacity: 0;
    transition: .4s cubic-bezier(0.215,0.61,0.355,1);
    transition-property: transform, opacity;
    transform: translateX(20px);
}
.pxp-hero-stats-item.pxp-has-animation.pxp-animate {
    opacity: 1;
    transform: translateX(0);
}
.pxp-hero-stats-item:nth-child(1) {
    top: 20%;
    right: 20%;
}
.pxp-hero-stats-item:nth-child(2) {
    top: 40%;
    right: 23%;
}
.pxp-hero-stats-item:nth-child(3) {
    top: 60%;
    right: 26%;
}
.pxp-hero-stats-item-number {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    white-space: nowrap;
}
.pxp-hero-stats-item-number > span {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}
.pxp-hero-stats-item-description {
    font-size: 13px;
    line-height: 13px;
    opacity: .7;
    white-space: nowrap;
}
.pxp-header-side-image img {
    width: 100%;
    height: auto;
}
.pxp-header-side-image.pxp-has-animation {
    transition: .6s cubic-bezier(0.215,0.61,0.355,1);
    transition-property: opacity, transform;
    transform: translateX(50px);
    opacity: 0;
}
.pxp-header-side-image.pxp-has-animation.pxp-animate {
    transform: translateX(0);
    opacity: 1;
}
.pxp-hero.pxp-hero-boxed {
    margin-top: 120px;
    min-height: auto;
}
.pxp-hero-boxed-content {
    padding: 80px;
    margin-left: -80px;
    margin-right: -80px;
    border-radius: 60px;
}
.pxp-hero-boxed-circulars {
    width: 100%;
    position: relative;
}
.pxp-hero-boxed-circulars.pxp-animate-circles-bounce {
    opacity: 0;
}
.pxp-hero-boxed-circulars.pxp-animate-circles-bounce.animate__animated {
    opacity: 1;
}
.pxp-hero-boxed-circular-outer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 2px solid #D3DFEA;
    border-radius: 50%;
    animation: spinRight 50s linear infinite;
}
@keyframes spinRight {
    100% { 
        transform: rotate(360deg);
    } 
}
.pxp-hero-boxed-circular-outer-1 {
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 14px;
    height: 14px;
    background-color: #D3DFEA;
    border-radius: 50%;

}
.pxp-hero-boxed-circular-outer-2 {
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: #D3DFEA;
    border-radius: 50%;

}
.pxp-hero-boxed-circular-outer-3 {
    position: absolute;
    top: 50%;
    right: -13px;
    transform: translateY(-50%);
    width: 26px;
    height: 26px;
    background-color: #D3DFEA;
    border-radius: 50%;
}
.pxp-hero-boxed-circular-middle {
    position: absolute;
    top: 48px;
    right: 48px;
    bottom: 48px;
    left: 48px;
    border: 2px solid #97ABBA;
    border-radius: 50%;
    animation: spinLeft 40s linear infinite;
}
@keyframes spinLeft {
    100% { 
        transform: rotate(-360deg);
    } 
}
.pxp-hero-boxed-circular-middle-1 {
    position: absolute;
    top: -7px;
    left: 50%;
    transform: translateX(-50%);
    width: 14px;
    height: 14px;
    background-color: #97ABBA;
    border-radius: 50%;
}
.pxp-hero-boxed-circular-middle-2 {
    position: absolute;
    top: 50%;
    left: -10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: #97ABBA;
    border-radius: 50%;

}
.pxp-hero-boxed-circular-middle-3 {
    position: absolute;
    top: 50%;
    right: -13px;
    transform: translateY(-50%);
    width: 26px;
    height: 26px;
    background-color: #97ABBA;
    border-radius: 50%;
}
.pxp-hero-boxed-circular-center {
    position: absolute;
    top: 96px;
    right: 96px;
    bottom: 96px;
    left: 96px;
    border-radius: 50%;
    background-color: #002745;
}
.pxp-hero-boxed-icon-circles {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.pxp-hero-boxed-icon-circle-1 {
    position: absolute;
    top: 140px;
    left: 45%;
    transform: translateX(-50%);
    background-color: var(--pxpMainColorLight);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pxp-hero-boxed-icon-circle-1.pxp-animate-icon-circle-bounce {
    opacity: 0;
}
.pxp-hero-boxed-icon-circle-1.pxp-animate-icon-circle-bounce.animate__animated {
    opacity: 1;
}
.pxp-hero-boxed-icon-circle-1 img {
    width: 24px;
    height: 30px;
}
.pxp-hero-boxed-icon-circle-2 {
    position: absolute;
    bottom: 190px;
    right: 190px;
    background-color: var(--pxpSecondaryColor);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pxp-hero-boxed-icon-circle-2.pxp-animate-icon-circle-bounce {
    opacity: 0;
}
.pxp-hero-boxed-icon-circle-2.pxp-animate-icon-circle-bounce.animate__animated {
    opacity: 1;
}
.pxp-hero-boxed-icon-circle-2 img {
    width: 40px;
    height: 40px;
}
.pxp-hero-boxed-info-cards {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.pxp-hero-boxed-info-card-big {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 60px;
    max-width: 260px;
}
.pxp-hero-boxed-info-card-big-content {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
    text-align: center;
}
.pxp-hero-boxed-info-card-big-icon {
    position: relative;
    transform: translateY(-60px);
    display: inline-block;
}
.pxp-hero-boxed-info-card-big-icon::before {
    content: "";
    background-color: var(--pxpSecondaryColor);
    display: block;
    position: absolute;
    z-index: 1;
    width: 100px;
    height: 100px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -20px;
    border-radius: 30px;
}
.pxp-hero-boxed-info-card-big-icon img {
    position: relative;
    height: 112px;
    width: auto;
    z-index: 2;
}
.pxp-hero-boxed-info-card-big-title {
    padding: 0 30px;
    font-size: 18px;
    font-weight: 600;
}
.pxp-hero-boxed-info-card-big-text {
    padding: 30px;
}
.pxp-hero-boxed-info-card-small {
    position: absolute;
    top: 30%;
    left: 0;
    max-width: 260px;
}
.pxp-hero-boxed-info-card-small-content {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    height: 100px;
}
.pxp-hero-boxed-info-card-small-icon {
    position: relative;
    transform: translate(-28px,30px);
}
.pxp-hero-boxed-info-card-small-icon::before {
    content: "";
    background-color: var(--pxpSecondaryColor);
    display: block;
    position: absolute;
    z-index: 1;
    width: 75px;
    height: 75px;
    top: -23px;
    left: 40px;
    border-radius: 20px;
}
.pxp-hero-boxed-info-card-small-icon img {
    position: relative;
    height: 90px;
    width: auto;
    z-index: 2;
}
.pxp-hero-boxed-info-card-small-title {
    font-size: 18px;
    font-weight: 600;
}
.pxp-hero-boxed-info-list-container {
    position: absolute;
    left: 10%;
    bottom: 0;
}
.pxp-hero-boxed-info-list {
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 15px 30px rgba(0,0,0,0.2);
    overflow: hidden;
    max-height: 255px;
    padding: 15px 30px;
    width: 270px;
    height: 255px;
    opacity: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.pxp-hero-boxed-info-list-item {
    display: block;
    padding: 15px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.pxp-hero-boxed-info-list-item-number {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    white-space: nowrap; 
}
.pxp-hero-boxed-info-list-item-number > span {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}
.pxp-hero-boxed-info-list-item-description {
    font-size: 13px;
    line-height: 13px;
    opacity: .7;
    white-space: nowrap;
}
.pxp-animate-info-card {
    opacity: 0;
}
.pxp-animate-info-card.animate__animated {
    opacity: 1;
}
.pxp-hero-opacity {
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.pxp-hero-opacity.pxp-opacity-10 {
    background-color: rgba(0,0,0,0.1);
}
.pxp-hero-opacity.pxp-opacity-20 {
    background-color: rgba(0,0,0,0.2);
}
.pxp-page-header-simple {
    padding: 60px 0;
    margin-top: 80px;
}
.pxp-page-header-simple h1 {
    font-weight: 700;
    font-size: 48px;
    letter-spacing: -2px;
}
.pxp-page-image-hero {
    background-color: var(--pxpMainColorLight);
    height: 460px;
    position: relative;
}
.pxp-page-image-hero-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60px;
    z-index: 2;
}
.pxp-page-image-hero h1 {
    font-weight: 700;
    font-size: 58px;
    letter-spacing: -2px;
    color: #fff;
}
.pxp-no-hero {
    padding-top: 80px;
}

/**
 * 5.0 - Categories section
 */

.pxp-categories-card-1-container,
.pxp-categories-card-2-container,
.pxp-categories-card-3-container {
    margin-top: 30px;
}
.pxp-categories-card-1,
.pxp-categories-card-2 {
    padding: 30px;
    background-color: var(--pxpMainColorLight);
    text-align: center;
    border-radius: 30px;
    text-decoration: none;
    transition: var(--pxpHoverTransition);
    transition-property: background-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.pxp-categories-card-1:hover,
.pxp-categories-card-2:hover {
    background-color: var(--pxpMainColorDark);
}
.pxp-categories-card-1-icon,
.pxp-categories-card-2-icon {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.04);
    padding: 30px;
    display: inline-block;
    font-size: 42px;
    line-height: 42px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: background-color, color;
}
.pxp-categories-card-2-icon {
    background-color: transparent;
    box-shadow: none;
}
.pxp-categories-card-1:hover .pxp-categories-card-1-icon,
.pxp-categories-card-2:hover .pxp-categories-card-2-icon {
    background-color: var(--pxpMainColor);
    color: #fff;
}
.pxp-categories-card-2:hover .pxp-categories-card-2-icon {
    background-color: transparent;
}
.pxp-categories-card-1-title,
.pxp-categories-card-2-title {
    margin-top: 30px;
    font-weight: 500;
    font-size: 18px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-categories-card-1:hover .pxp-categories-card-1-title,
.pxp-categories-card-2:hover .pxp-categories-card-2-title {
    color: #fff;
}
.pxp-categories-card-1-subtitle,
.pxp-categories-card-2-subtitle {
    margin-top: 30px;
    font-size: 14px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-categories-card-1:hover .pxp-categories-card-1-subtitle,
.pxp-categories-card-2:hover .pxp-categories-card-2-subtitle {
    color: #fff;
}
.pxp-categories-carousel.owl-carousel .owl-stage {
    display: flex;
}
.pxp-categories-carousel.owl-carousel .owl-dots {
    margin-top: 40px;
    text-align: center;
}
.pxp-categories-carousel.owl-carousel button.owl-dot {
    width: 10px;
    height: 10px;
    background-color: var(--pxpTextColor);
    border-radius: 5px;
    margin: 0 5px;
    opacity: .5;
    transition: var(--pxpHoverTransition);
    transition-property: width, opacity;
}
.pxp-categories-carousel.owl-carousel button.owl-dot.active {
    opacity: 1;
    width: 30px;
}
.pxp-categories-card-3 {
    border-radius: 30px;
    text-decoration: none;
    transition: var(--pxpHoverTransition);
    transition-property: background-color;
    display: flex;
    height: 100%;
    border: 1px solid var(--pxpMainColorLight);
    align-items: center;
}
.pxp-categories-card-3:hover {
    background-color: var(--pxpMainColorLight);
}
.pxp-categories-card-3-icon {
    background-color: var(--pxpMainColorLight);
    border-radius: 30px;
    padding: 30px;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 42px;
    line-height: 42px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: background-color, color;
    text-align: center;
}
.pxp-categories-card-3:hover .pxp-categories-card-3-icon {
    background-color: var(--pxpMainColor);
    color: #fff;
}
.pxp-categories-card-3-icon > span {
    width: 48px;
}
.pxp-categories-card-3-text {
    padding: 20px 30px;
}
.pxp-categories-card-3-title {
    font-weight: 500;
    font-size: 18px;
    color: var(--pxpTextColor);
}
.pxp-categories-card-3-subtitle {
    margin-top: 10px;
    font-size: 14px;
    color: var(--pxpTextColor);
}

/**
 * 6.0 - Jobs section
 */

.pxp-jobs-card-1-container,
.pxp-jobs-card-2-container {
    margin-top: 30px;
}
.pxp-jobs-card-1,
.pxp-jobs-card-2,
.pxp-jobs-card-3 {
    background-color: #fff;
    padding: 30px;
    border-radius: 30px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.pxp-jobs-card-3 {
    height: auto;
    margin-top: 30px;
}
.pxp-jobs-card-1.pxp-has-shadow,
.pxp-jobs-card-2.pxp-has-shadow,
.pxp-jobs-card-3.pxp-has-shadow {
    box-shadow: 0px 10px 20px rgba(0,0,0,0.04);
}
.pxp-jobs-card-1.pxp-has-border,
.pxp-jobs-card-2.pxp-has-border,
.pxp-jobs-card-3.pxp-has-border {
    border: 1px solid var(--pxpLightGrayColor);
}
.pxp-jobs-card-2-top {
    display: flex;
    align-items: center;
}
.pxp-jobs-card-1-category {
    display: flex;
    align-items: center;
    text-decoration: none;
}
.pxp-jobs-card-1-category-icon {
    background-color: var(--pxpMainColorLight);
    border-radius: 10px;
    padding: 8px;
    font-size: 14px;
    line-height: 14px;
    color: var(--pxpTextColor);
    text-decoration: none;
    transition: var(--pxpHoverTransition);
    transition-property: color, background-color;
}
.pxp-jobs-card-1-category:hover .pxp-jobs-card-1-category-icon {
    background-color: var(--pxpMainColor);
    color: #fff;
}
.pxp-jobs-card-1-category-label,
.pxp-jobs-card-2-category-label,
.pxp-jobs-card-3-category-label {
    padding-left: 10px;
    font-size: 14px;
    color: var(--pxpTextColor);
    text-decoration: none;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-jobs-card-2-category-label,
.pxp-jobs-card-3-category-label {
    padding-left: 0;
}
.pxp-jobs-card-1-category:hover .pxp-jobs-card-1-category-label,
.pxp-jobs-card-2-category:hover .pxp-jobs-card-2-category-label,
.pxp-jobs-card-3-category:hover .pxp-jobs-card-3-category-label {
    color: var(--pxpMainColor);
}
.pxp-jobs-card-1-title,
.pxp-jobs-card-2-title,
.pxp-jobs-card-3-title {
    display: block;
    font-weight: 600;
    color: var(--pxpTextColor);
    font-size: 18px;
    margin-top: 30px;
    transition: var(--pxpHoverTransition);
    transition-property: color;
    text-decoration: none;
}
.pxp-jobs-card-2-title,
.pxp-jobs-card-3-title {
    margin-top: 0;
}
.pxp-jobs-card-1-title:hover,
.pxp-jobs-card-2-title:hover, 
.pxp-jobs-card-3-title:hover {
    color: var(--pxpMainColor);
}
.pxp-jobs-card-1-details,
.pxp-jobs-card-2-details,
.pxp-jobs-card-3-details {
    margin-top: 20px;
    display: flex;
    font-size: 14px;
    color: var(--pxpTextColor);
}
.pxp-jobs-card-2-details,
.pxp-jobs-card-3-details {
    margin-top: 10px;
}
.pxp-jobs-card-1-location,
.pxp-jobs-card-2-location,
.pxp-jobs-card-3-location {
    display: block;
    font-weight: 500;
    text-decoration: none;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-jobs-card-1-location:hover,
.pxp-jobs-card-2-location:hover,
.pxp-jobs-card-3-location:hover {
    color: var(--pxpMainColor);
}
.pxp-jobs-card-1-location > span,
.pxp-jobs-card-2-location > span,
.pxp-jobs-card-3-location > span {
    margin-right: 7px;
}
.pxp-jobs-card-1-type,
.pxp-jobs-card-2-type,
.pxp-jobs-card-3-type {
    padding-left: 30px;
}
.pxp-jobs-card-1-bottom,
.pxp-jobs-card-2-bottom {
    display: flex;
    margin-top: 40px;
    align-items: center;
    justify-content: space-between;
}
.pxp-jobs-card-2-bottom {
    margin-top: 20px;
}
.pxp-jobs-card-1-bottom-left {
    padding-right: 30px;
}
.pxp-jobs-card-1-date,
.pxp-jobs-card-2-date,
.pxp-jobs-card-3-date {
    font-size: 13px;
    color: var(--pxpTextColor);
}
.pxp-jobs-card-1-company,
.pxp-jobs-card-2-company,
.pxp-jobs-card-3-company {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
    text-decoration: none;
}
.pxp-jobs-card-2-company,
.pxp-jobs-card-3-company {
    display: unset;
}
.pxp-jobs-card-1-company:hover, 
.pxp-jobs-card-2-company:hover,
.pxp-jobs-card-3-company:hover {
    color: var(--pxpMainColor);
}
.pxp-jobs-card-1-company-logo, 
.pxp-jobs-card-2-company-logo,
.pxp-jobs-card-3-company-logo {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 20px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
}
.pxp-jobs-card-2-company-logo {
    flex-shrink: 0;
}
.pxp-jobs-card-2-info {
    margin-left: 20px;
}
.pxp-jobs-card-3-date-company {
    margin-top: 7px;
}
.pxp-jobs-card-4 {
    background-color: #fff;
    padding: 30px;
    border-radius: 30px;
    text-decoration: none;
    display: block;
    border: 1px solid var(--pxpLightGrayColor);
    margin-top: 14px;
    width: 100%;
    transition: var(--pxpHoverTransition);
    transition-property: background-color, border-color;
    cursor: pointer;
}
.pxp-jobs-card-4.active {
    background-color: var(--pxpMainColor);
    border-color: transparent;
}
.pxp-jobs-card-4:not(.active):hover {
    background-color: var(--pxpMainColorLight);
    border-color: transparent;
}
.pxp-jobs-card-4:first-child {
    margin-top: 0;
}
.pxp-jobs-card-4-company-logo {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 14px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
.pxp-jobs-card-4.active .pxp-jobs-card-4-company-logo {
    border: 2px solid var(--pxpMainColorLight);
}
.pxp-jobs-card-4-company-top {
    display: flex;
    align-items: center;
}
.pxp-jobs-card-4-info {
    padding-left: 10px;
    flex: 1;
}
.pxp-jobs-card-4-title {
    display: block;
    font-weight: 600;
    text-decoration: none;
    color: var(--pxpTextColor);
    line-height: 1.2;
    font-size: 15px;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-jobs-card-4.active .pxp-jobs-card-4-title {
    color: #fff;
}
.pxp-jobs-card-4:not(.active):hover .pxp-jobs-card-4-title {
    color: var(--pxpMainColor);
}
.pxp-jobs-card-4-details {
    display: flex;
    font-size: 13px;
    color: var(--pxpTextColor);
    margin-top: 5px;
}
.pxp-jobs-card-4-location {
    display: block;
    font-weight: 500;
    text-decoration: none;
    color: var(--pxpTextColor);
}
.pxp-jobs-card-4.active .pxp-jobs-card-4-location {
    color: #fff;
}
.pxp-jobs-card-4-location > span {
    margin-right: 7px;
}
.pxp-jobs-card-4-type {
    padding-left: 30px;
    color: var(--pxpTextColor);
}
.pxp-jobs-card-4.active .pxp-jobs-card-4-type {
    color: #fff;
}
.pxp-jobs-card-4-company-bottom {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pxp-jobs-card-4-category-label {
    font-size: 13px;
    color: var(--pxpTextColor);
}
.pxp-jobs-card-4.active .pxp-jobs-card-4-category-label {
    color: #fff;
}
.pxp-jobs-card-4-date {
    display: block;
    font-size: 13px;
    color: var(--pxpTextColor);
}
.pxp-jobs-card-4.active .pxp-jobs-card-4-date {
    color: #fff;
}
.pxp-jobs-card-4-company {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: var(--pxpTextColor);
}
.pxp-jobs-card-4.active .pxp-jobs-card-4-company {
    color: #fff;
}
.pxp-jobs-tab-content .tab-pane {
    border: 1px solid var(--pxpLightGrayColor);
    border-radius: 30px;
    background-color: #fff;
}
.pxp-jobs-tab-pane-cover {
    height: 260px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    margin: -1px -1px 0 -1px;
}
.pxp-jobs-tab-pane-logo {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 3px solid #fff;
    margin-top: -50px;
    margin-left: 30px;
}
.pxp-jobs-tab-pane-content {
    padding: 30px;
}
.pxp-jobs-tab-pane-content h3 {
    font-weight: 700;
    font-size: 28px;
    letter-spacing: -1px;
}
.pxp-jobs-tab-pane-company-location {
    font-size: 15px;
}
.pxp-jobs-tab-pane-company,
.pxp-jobs-tab-pane-location {
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-jobs-tab-pane-location {
    font-weight: 300;
}
.pxp-jobs-tab-pane-options {
    display: flex;
    align-items: center;
}
.pxp-jobs-tab-pane-options .pxp-jobs-tab-pane-save-btn,
.pxp-jobs-tab-pane-options .pxp-jobs-tab-pane-share-btn {
    border: 1px solid var(--pxpTextColor);
    color: var(--pxpTextColor);
    text-align: center;
    height: 46px;
    width: 46px;
    border-radius: 23px;
    font-size: 15px;
    transition: var(--pxpHoverTransition);
    transition-property: color, background-color;
}
.pxp-jobs-tab-pane-options .pxp-jobs-tab-pane-save-btn:hover,
.pxp-jobs-tab-pane-options .pxp-jobs-tab-pane-share-btn:hover {
    color: #fff;
    background-color: var(--pxpTextColor);
}
.pxp-jobs-tab-pane-options .pxp-jobs-tab-pane-share-btn::after {
    content: none;
}
.pxp-jobs-tab-pane-options .pxp-jobs-tab-pane-apply-btn {
    height: 46px;
    background-color: var(--pxpMainColor);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: color, background-color;
    padding: .375rem 1rem;
}
.pxp-jobs-tab-pane-options .pxp-jobs-tab-pane-apply-btn:hover {
    color: #fff;
    background-color: var(--pxpMainColorDark);
}
.pxp-jobs-tab-pane-options .dropdown-menu {
    margin-top: 7px !important;
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0px 15px 30px rgba(0,0,0,0.10);
}
.pxp-jobs-tab-pane-options ul.dropdown-menu > li > a {
    font-size: 14px;
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: color, opacity;
    padding: 7px 20px;
    color: var(--pxpTextColor);
}
.pxp-jobs-tab-pane-options ul.dropdown-menu > li > a:hover,
.pxp-jobs-tab-pane-options ul.dropdown-menu > li > a:focus {
    background-color: transparent;
    opacity: var(--pxpHoverOpacity);
}
.pxp-jobs-tab-pane-date {
    font-size: 14px;
}
.pxp-jobs-tab-pane-category {
    display: flex;
    align-items: center;
    text-decoration: none;
}
.pxp-jobs-tab-pane-category-icon {
    background-color: var(--pxpMainColorLight);
    border-radius: 10px;
    padding: 8px;
    font-size: 14px;
    line-height: 14px;
    color: var(--pxpTextColor);
    text-decoration: none;
    transition: var(--pxpHoverTransition);
    transition-property: color, background-color;
}
.pxp-jobs-tab-pane-category:hover .pxp-jobs-tab-pane-category-icon {
    background-color: var(--pxpMainColor);
    color: #fff;
}
.pxp-jobs-tab-pane-category-label {
    padding-left: 10px;
    font-size: 14px;
    color: var(--pxpTextColor);
    text-decoration: none;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-jobs-tab-pane-category:hover .pxp-jobs-tab-pane-category-label {
    color: var(--pxpMainColor);
}
.pxp-jobs-tab-pane-content-details {
    font-size: 14px;
}
.pxp-jobs-tab-pane-content-details h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}
.pxp-jobs-tab-pane-content-details p,
.pxp-jobs-tab-pane-content-details li {
    line-height: 1.7;
}
.pxp-jobs-tab-pane-content-details li {
    margin-bottom: 10px;
}
.pxp-jobs-tab-pane-content-info-label {
    font-size: 12px;
}
.pxp-jobs-tab-pane-content-info-data {
    font-weight: 500;
    font-size: 15px;
}
.pxp-jobs-tab-pane-share-dropdown .dropdown-item > span {
    width: 20px;
    text-align: center;
}

/**
 * 7.0 - Promo image section
 */

.pxp-promo-img {
    border-radius: 50px;
    padding-left: 80px;
}
.pxp-promo-columns-text.pxp-left {
    margin-left: 80px;
}
.pxp-promo-columns-text.pxp-right {
    margin-right: 80px;
}
.pxp-promo-columns-image img {
    width: 100%;
    height: auto;
}

/**
 * 8.0 - Companies section
 */

.pxp-companies-card-1-container {
    margin-top: 30px;
}
.pxp-companies-card-1 {
    background-color: #fff;
    padding: 30px;
    border-radius: 30px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.pxp-companies-card-1.pxp-has-shadow {
    box-shadow: 0px 10px 20px rgba(0,0,0,0.04);
}
.pxp-companies-card-1.pxp-has-border {
    border: 1px solid var(--pxpLightGrayColor);
}
.pxp-companies-card-1-company-logo {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 20px;
    background-position: center center;
    background-size: contain;
}
.pxp-companies-card-1-company-name {
    display: block;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
    text-decoration: none;
}
.pxp-companies-card-1-company-name:hover {
    color: var(--pxpMainColor);
}
.pxp-companies-card-1-company-description {
    font-size: 14px;
    margin-top: 20px;
}
.pxp-companies-card-1-bottom {
    margin-top: 30px;
}
.pxp-companies-card-1-company-jobs {
    font-size: 16px;
    font-weight: 500;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
    text-decoration: none;
}
.pxp-companies-card-1-company-jobs:hover {
    color: var(--pxpMainColor);
}

/**
 * 9.0 - Companies section
 */

.pxp-services-1-item-container {
    margin-top: 30px;
}
.pxp-services-1-item-icon {
    position: relative;
}
.pxp-services-1-item-icon::before {
    content: "";
    background-color: var(--pxpSecondaryColor);
    display: block;
    position: absolute;
    z-index: 1;
    width: 136px;
    height: 136px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
    border-radius: 30px;
}
.pxp-services-1-item-icon img {
    position: relative;
    height: 148px;
    width: auto;
    z-index: 2;
}
.pxp-services-1-item-title {
    display: block;
    font-size: 18px;
    font-weight: 600;
    margin-top: 60px;
}
.pxp-services-1-item-text {
    margin-top: 20px;
    font-size: 15px;
}
.pxp-services-1-item-cta {
    margin-top: 30px;
}
.pxp-services-1-item-cta > a {
    font-weight: 500;
    color: var(--pxpMainColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-services-1-item-cta > a:hover {
    color: var(--pxpMainColorDark);
}
.pxp-services-1-item-cta > a > span {
    margin-left: 10px;
    transition: var(--pxpHoverTransition);
    transition-property: margin-left;
}
.pxp-services-1-item-cta > a:hover > span {
    margin-left: 20px;
}

/**
 * 10.0 - Info section
 */

.pxp-info-fig {
    position: relative;
    padding-bottom: 100px;
    padding-right: 200px;
}
.pxp-info-fig-image {
    position: relative;
    border-radius: 40px;
    height: 600px;
    z-index: 2;
}
.pxp-info-fig::before {
    content: "";
    background-color: var(--pxpSecondaryColor);
    display: block;
    position: absolute;
    z-index: 1;
    height: 600px;
    left: 100px;
    right: 100px;
    bottom: 0;
    border-radius: 40px;
}
.pxp-info-caption-list {
    margin-top: 60px;
}
.pxp-info-caption-list-item {
    display: flex;
    font-weight: 600;
    align-items: center;
    margin: 20px 0;
}
.pxp-info-caption-list-item img {
    width: 26px;
    height: auto;
}
.pxp-info-caption-list-item span {
    margin-left: 20px;
    font-weight: 500;
}
.pxp-info-caption-cta {
    margin-top: 60px;
}
.pxp-info-stats {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 3;
}
.pxp-info-stats-item {
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
    border-radius: 30px;
    overflow: hidden;
    padding: 30px;
    margin-top: 10px;
}
.pxp-info-stats-item:first-child {
    margin-top: 0;
}
.pxp-info-stats-item.pxp-animate-bounce {
    opacity: 0;
}
.pxp-info-stats-item.pxp-animate-bounce.animate__animated {
    opacity: 1;
}
.pxp-info-stats-item-number {
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    white-space: nowrap;
}
.pxp-info-stats-item-number > span {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}
.pxp-info-stats-item-description {
    font-size: 13px;
    line-height: 13px;
    opacity: .7;
    white-space: nowrap;
}

/**
 * 11.0 - Testimonials section
 */

.pxp-testimonials-1 {
    position: relative;
    margin-top: 60px;
}
.pxp-testimonials-1-circles {
    height: 500px;
    position: relative;
}
.pxp-testimonials-1-circles-item {
    position: absolute;
    border-radius: 50%;
}
.pxp-testimonials-1-circles-item.pxp-item-1 {
    top: 0;
    left: 15%;
    background-color: var(--pxpSecondaryColor);
    width: 130px;
    height: 130px;
    z-index: 2;
}
.pxp-testimonials-1-circles-item.pxp-item-2 {
    top: 150px;
    left: 20%;
    background-color: var(--pxpMainColorLight);
    width: 140px;
    height: 140px;
}
.pxp-testimonials-1-circles-item.pxp-item-3 {
    bottom: 0;
    left: 0;
    background-color: var(--pxpSecondaryColor);
    width: 230px;
    height: 230px;
}
.pxp-testimonials-1-circles-item.pxp-item-4 {
    top: 230px;
    left: 3%;
    background-color: var(--pxpMainColorLight);
    width: 100px;
    height: 100px;
    z-index: 2;
}
.pxp-testimonials-1-circles-item.pxp-item-5 {
    bottom: 40px;
    left: 18%;
    background-color: var(--pxpMainColorLight);
    width: 80px;
    height: 80px;
    z-index: 2;
}
.pxp-testimonials-1-circles-item.pxp-item-6 {
    top: 0;
    right: 20%;
    background-color: var(--pxpMainColorLight);
    width: 140px;
    height: 140px;
}
.pxp-testimonials-1-circles-item.pxp-item-7 {
    top: 50px;
    right: 15%;
    background-color: var(--pxpSecondaryColor);
    width: 100px;
    height: 100px;
    z-index: 2;
}
.pxp-testimonials-1-circles-item.pxp-item-8 {
    bottom: 100px;
    right: 3%;
    background-color: var(--pxpSecondaryColor);
    width: 230px;
    height: 230px;
}
.pxp-testimonials-1-circles-item.pxp-item-9 {
    top: 250px;
    right: 0;
    background-color: var(--pxpMainColorLight);
    width: 80px;
    height: 80px;
    z-index: 2;
}
.pxp-testimonials-1-circles-item.pxp-item-10 {
    bottom: 0;
    right: 15%;
    background-color: var(--pxpMainColorLight);
    width: 130px;
    height: 130px;
    z-index: 2;
}
.pxp-testimonials-1-circles-item.pxp-animate-bounce {
    opacity: 0;
}
.pxp-testimonials-1-circles-item.pxp-animate-bounce.animate__animated {
    opacity: 1;
}
.pxp-testimonials-1-carousel-container {
    position: absolute;
    top: 0;
    height: 500px;
    left: 0;
    right: 0;
    z-index: 3;
}
.pxp-testimonials-1-carousel-container > .row {
    height: 500px;
}
.pxp-testimonials-1-carousel-item-text {
    font-style: italic;
}
.pxp-testimonials-1-carousel-item-name {
    font-size: 18px;
    font-weight: 600;
    margin-top: 40px;
}
.pxp-testimonials-1-carousel-item-company {
    font-size: 14px;
    margin-top: 10px;
}
.pxp-testimonials-1-carousel .carousel-control-next, 
.pxp-testimonials-1-carousel .carousel-control-prev {
    color: var(--pxpTextColor);
    font-size: 36px;
    opacity: 1;
}
.pxp-testimonials-1-carousel .carousel-control-next {
    right: -15%;
}
.pxp-testimonials-1-carousel .carousel-control-prev {
    left: -15%;
}
.pxp-testimonials-1-carousel .carousel-control-next:focus, 
.pxp-testimonials-1-carousel .carousel-control-next:hover, 
.pxp-testimonials-1-carousel .carousel-control-prev:focus, 
.pxp-testimonials-1-carousel .carousel-control-prev:hover {
    color: var(--pxpTextColor);
    opacity: .7;
}

/**
 * 12.0 - Posts section
 */

.pxp-posts-card-1-container {
    margin-top: 30px;
}
.pxp-posts-card-1 {
    background-color: #fff;
    border-radius: 30px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.pxp-posts-card-1.pxp-has-shadow {
    box-shadow: 0px 10px 20px rgba(0,0,0,0.04);
}
.pxp-posts-card-1.pxp-has-border {
    border: 1px solid var(--pxpLightGrayColor);
}
.pxp-posts-card-1-top-bg {
    background-color: var(--pxpMainColorLight);
    border-radius: 30px;
    margin: -1px;
}
.pxp-posts-card-1-image {
    height: 240px;
    border-radius: 30px;
}
.pxp-posts-card-1-info {
    padding: 30px;
}
.pxp-posts-card-1-date {
    font-size: 13px;
}
.pxp-posts-card-1-category {
    display: block;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
    text-decoration: none;
}
.pxp-posts-card-1-category:hover {
    color: var(--pxpMainColor);
}
.pxp-posts-card-1-content {
    padding: 30px 30px 0;
}
.pxp-posts-card-1-title {
    display: block;
    font-weight: 600;
    color: var(--pxpTextColor);
    font-size: 18px;
    transition: var(--pxpHoverTransition);
    transition-property: color;
    text-decoration: none;
}
.pxp-posts-card-1-title:hover {
    color: var(--pxpMainColor);
}
.pxp-posts-card-1-summary {
    font-size: 14px;
    margin-top: 10px;
}
.pxp-posts-card-1-bottom {
    margin-top: 30px;
}
.pxp-posts-card-1-cta {
    padding: 0 30px 30px;
}
.pxp-posts-card-1-cta > a {
    font-weight: 500;
    color: var(--pxpMainColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-posts-card-1-cta > a:hover {
    color: var(--pxpMainColorDark);
}
.pxp-posts-card-1-cta > a > span {
    margin-left: 10px;
    transition: var(--pxpHoverTransition);
    transition-property: margin-left;
}
.pxp-posts-card-1-cta > a:hover > span {
    margin-left: 20px;
}

/**
 * 13.0 - Cities section
 */

.pxp-cities-card-1-container {
    margin-top: 30px;
    padding-top: 50px;
}
.pxp-cities-card-1 {
    background-color: var(--pxpMainColorLight);
    border-radius: 30px;
    padding: 0 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.pxp-cities-card-1-image {
    border-radius: 30px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
    display: inline-block;
    width: 100px;
    height: 100px;
    margin-top: -50px;
    transition: var(--pxpHoverTransition);
    transition-property: transform;
}
.pxp-cities-card-1:hover .pxp-cities-card-1-image {
    transform: scale(1.04);
}
.pxp-cities-card-1-name {
    font-weight: 500;
    margin-top: 20px;
    font-size: 18px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-cities-card-1:hover .pxp-cities-card-1-name {
    color: var(--pxpMainColor);
}
.pxp-cities-card-1-bottom {
    margin-top: 10px;
}
.pxp-cities-card-1-jobs {
    font-size: 13px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-cities-card-1:hover .pxp-cities-card-1-jobs {
    color: var(--pxpMainColor);
}
.pxp-cities-card-2 {
    display: block;
    background-color: var(--pxpSecondaryColor);
    border-radius: 30px;
    margin-top: 30px;
}
.pxp-cities-card-2-image-container {
    height: 170px;
    border-radius: 30px;
    overflow: hidden;
}
.pxp-cities-card-2.pxp-is-featured .pxp-cities-card-2-image-container {
    height: 480px;
}
.pxp-cities-card-2-image {
    height: 170px;
    transition: 1s cubic-bezier(0.215,0.61,0.355,1);
    transition-property: transform;
}
.pxp-cities-card-2.pxp-is-featured .pxp-cities-card-2-image {
    height: 480px;
}
.pxp-cities-card-2:hover .pxp-cities-card-2-image {
    transform: scale(1.1);
}
.pxp-cities-card-2-info {
    padding: 30px;
}
.pxp-cities-card-2-name {
    font-weight: 500;
    font-size: 18px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-cities-card-2:hover .pxp-cities-card-2-name {
    color: var(--pxpMainColor);
}
.pxp-cities-card-2-jobs {
    font-size: 13px;
    margin-top: 4px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-cities-card-2:hover .pxp-cities-card-2-jobs {
    color: var(--pxpMainColor);
}

/**
 * 14.0 - Candidates section
 */

.pxp-candiadates-card-1-container {
    margin-top: 30px;
}
.pxp-candiadates-card-1 {
    background-color: #fff;
    padding: 30px;
    border-radius: 30px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.pxp-candiadates-card-1.pxp-has-shadow {
    box-shadow: 0px 10px 20px rgba(0,0,0,0.04);
}
.pxp-candiadates-card-1.pxp-has-border {
    border: 1px solid var(--pxpLightGrayColor);
}
.pxp-candiadates-card-1-avatar {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
}
.pxp-candiadates-card-1-name {
    font-weight: 600;
    font-size: 18px;
    margin-top: 30px;
}
.pxp-candiadates-card-1-title {
    font-size: 15px;
    margin-top: 4px;
}
.pxp-candiadates-card-1-location {
    font-size: 14px;
    font-weight: 500;
    margin-top: 30px;
}
.pxp-candiadates-card-1-location > span {
    margin-right: 7px;
}
.pxp-candiadates-card-1-bottom {
    margin-top: 30px;
}
.pxp-candiadates-card-1-cta > a {
    font-weight: 500;
    color: var(--pxpMainColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-candiadates-card-1-cta > a:hover {
    color: var(--pxpMainColorDark);
}
.pxp-candiadates-card-1-cta > a > span {
    margin-left: 10px;
    transition: var(--pxpHoverTransition);
    transition-property: margin-left;
}
.pxp-candiadates-card-1-cta > a:hover > span {
    margin-left: 20px;
}

/**
 * 15.0 - Price plans section
 */

.pxp-plans-card-1-container {
    margin-top: 30px;
}
.pxp-plans-card-1 {
    background-color: var(--pxpMainColorLight);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 30px;
    position: relative;
}
.pxp-plans-card-1.pxp-is-featured {
    background-color: var(--pxpMainColorDark);
}
.pxp-plans-card-1-featured-label {
    position: absolute;
    right: 30px;
    top: 0;
    transform: translateY(-50%);
    background-color: var(--pxpSecondaryColor);
    font-size: 13px;
    padding: 10px 16px;
    border-radius: 20px;
    font-weight: 500;
}
.pxp-plans-card-1-title {
    font-size: 22px;
    font-weight: 600;
}
.pxp-plans-card-1-price {
    font-size: 36px;
    font-weight: 700;
    margin-top: 20px;
}
.pxp-plans-card-1.pxp-is-featured .pxp-plans-card-1-title,
.pxp-plans-card-1.pxp-is-featured .pxp-plans-card-1-price {
    color: var(--pxpSecondaryColor);
}
.pxp-plans-card-1-price span.pxp-period {
    font-size: 16px;
    font-weight: 300;
    margin-left: 10px;
}
.pxp-plans-card-1-list {
    margin-top: 40px;
}
.pxp-plans-card-1-list > ul > li {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size:  14px;
}
.pxp-plans-card-1.pxp-is-featured .pxp-plans-card-1-list > ul > li {
    color: #fff;
}
.pxp-plans-card-1-list > ul > li > img {
    width: 18px;
    height: auto;
    margin-right: 20px;
}
.pxp-plans-card-1-bottom {
    margin-top: 40px;
}
.pxp-card-btn {
    background-color: var(--pxpMainColor);
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    transition: var(--pxpHoverTransition);
    transition-property: background-color;
}
.pxp-card-btn:hover {
    color: #fff;
    background-color: var(--pxpMainColorDark);
}
.pxp-plans-card-1.pxp-is-featured .pxp-card-btn:hover {
    color: var(--pxpMainColorDark);
    background-color: #fff;
}
.pxp-plans-price-annual {
    display: none;
}
.pxp-price-plans-switcher .btn-outline-primary {
    color: var(--pxpMainColor);
    border-color: var(--pxpMainColorLight);
    border-radius: 20px;
    padding: 7px 16px;
    font-size: 14px;
}
.pxp-price-plans-switcher .btn-outline-primary:hover {
    color: #fff;
    border-color: var(--pxpMainColor);
    background-color: var(--pxpMainColor);
}
.pxp-price-plans-switcher .btn-check:active+.btn-outline-primary, 
.pxp-price-plans-switcher .btn-check:checked+.btn-outline-primary, 
.pxp-price-plans-switcher .btn-outline-primary.active, 
.pxp-price-plans-switcher .btn-outline-primary.dropdown-toggle.show, 
.pxp-price-plans-switcher .btn-outline-primary:active {
    color: #fff;
    background-color: var(--pxpMainColorDark);
    border-color: var(--pxpMainColorDark);
}
.pxp-price-plans-switcher .btn-outline-primary:focus,
.pxp-price-plans-switcher .btn-check:checked+.btn-outline-primary:focus,
.pxp-price-plans-switcher .btn-check:focus+.btn-outline-primary {
    box-shadow: none;
}

/**
 * 16.0 - Subscribe section
 */

.pxp-subscribe-1-image {
    text-align: center;
}
.pxp-subscribe-1-image img {
    width: 60%;
    height: auto;
}
.pxp-subscribe-1-form .form-control {
    font-weight: 300;
    padding: 16px 20px;
    border: 1px solid var(--pxpLightGrayColor);
    border-radius: 34px;
}
.pxp-subscribe-1-form .form-control:focus {
    z-index: 1;
    box-shadow: none;
    border: 1px solid var(--pxpMainColorDark);
}
.pxp-subscribe-1-form .btn {
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 34px;
    background-color: var(--pxpMainColor);
    transition: var(--pxpHoverTransition);
    transition-property: background-color;
    border: 0 none;
    font-size: 18px;
    font-weight: 500;
}
.pxp-subscribe-1-form .btn:hover {
    background-color: var(--pxpMainColorDark);
}
.pxp-subscribe-1-form .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-left-radius: 34px;
    border-bottom-left-radius: 34px;
    margin-left: -30px;
}
.pxp-subscribe-1-form .btn span {
    margin-left: 10px;
}
.pxp-subscribe-1-form .btn span {
    margin-left: 10px;
    transition: var(--pxpHoverTransition);
    transition-property: margin-left;
}
.pxp-subscribe-1-form .btn:hover span {
    margin-left: 20px;
}

/**
 * 17.0 - Lists section
 */

.pxp-lists-container {
    margin-top: 30px;
}
.pxp-list h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
}
.pxp-list ul li {
    margin: 10px 0;
}
.pxp-list ul li a {
    transition: var(--pxpHoverTransition);
    transition-property: color;
}

/**
 * 18.0 - Jobs list
 */

.pxp-jobs-list-side-filter h3 {
    font-size: 18px;
    font-weight: 600;
}
.pxp-jobs-list-side-filter .list-group-item {
    padding: 0;
    color: var(--pxpTextColor);
    border: 0 none;
    background-color: transparent;
}
.pxp-jobs-list-side-filter .form-check-input {
    border: 0 none;
    box-shadow: 0px 6px 12px rgba(0,0,0,0.05);
}
.pxp-jobs-list-side-filter .form-check-input:checked {
    background-color: var(--pxpMainColor);
}
.pxp-jobs-list-side-filter .form-check-input:focus {
    box-shadow: none;
}
.pxp-jobs-list-side-filter .list-group-item .badge {
    background-color: #fff;
    color: var(--pxpTextColor);
    font-weight: 600;
    opacity: .7;
}
.pxp-jobs-list-side-filter .list-group-item.pxp-checked .badge {
    background-color: var(--pxpMainColor);
    color: #fff;
    opacity: 1;
}
.pxp-jobs-list-side-filter {
    background-color: var(--pxpMainColorLight);
    padding: 30px;
    border-radius: 30px;
}
.pxp-list-side-filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pxp-list-side-filter-header-label {
    font-size: 22px;
    font-weight: 600;
}
.pxp-list-side-filter-header a {
    font-size: 20px;
    transition: var(--pxpHoverTransition);
    transition-property: opacity;
}
.pxp-list-side-filter-header a:hover {
    opacity: .7;
}
.pxp-jobs-list-top h2 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
}
.pxp-jobs-list-top .form-select {
    background-color: transparent;
    border: 0 none;
    padding: 0;
    font-weight: 500;
    color: var(--pxpTextColor);
    padding: 14px 20px;
    background-position: right center;
    font-size: 15px;
}
.pxp-jobs-list-top .form-select:focus {
    box-shadow: none;
}
.pxp-pagination {
    margin-bottom: 0;
}
.pxp-pagination .page-link {
    color: var(--pxpMainColor);
    border-color: var(--pxpMainColorLight);
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
}
.pxp-pagination .page-link:hover {
    color: #fff;
    border-color: var(--pxpMainColor);
    background-color: var(--pxpMainColor);
}
.pxp-pagination .page-link:focus,
.pxp-pagination .page-link:active {
    box-shadow: none;
    background-color: #fff;
    color: var(--pxpMainColor);
    border-color: var(--pxpMainColorLight);
    outline: 0;
}
.pxp-pagination .page-item.active .page-link {
    color: #fff;
    background-color: var(--pxpMainColorDark);
    border-color: var(--pxpMainColorDark);
}
.pxp-pagination .page-item:first-child .page-link {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.pxp-pagination .page-item:last-child .page-link {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.pxp-jobs-list-side-filter .input-group {
    box-shadow: 0px 6px 12px rgba(0,0,0,0.05);
    border-radius: 30px;
}
.pxp-jobs-list-side-filter .input-group .input-group-text {
    color: var(--pxpTextColor);
    border: 0 none;
    background-color: #fff;
    border-radius: 30px;
    padding: 1rem 2px 1rem 20px;
}
.pxp-jobs-list-side-filter .input-group .input-group-text > span {
    opacity: .7;
}
.pxp-jobs-list-side-filter .form-control,
.pxp-jobs-list-side-filter .form-select {
    border: 0 none;
    font-weight: 300;
    color: var(--pxpTextColor);
    background-color: #fff;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 1rem .75rem;
}
.pxp-jobs-list-side-filter .form-select {
    background-position: right 20px center;
}
.pxp-jobs-list-side-filter .form-control:focus,
.pxp-jobs-list-side-filter .form-select:focus {
    box-shadow: none;
}
.pxp-hero-form-filter .form-select {
    border: 0 none;
    font-weight: 300;
    color: var(--pxpTextColor);
    background-color: transparent;
    border-radius: 30px;
    padding: 1rem 3rem 1rem 1.5rem;
    background-position: right 20px center;
}
.pxp-hero-form-filter .form-select:focus {
    box-shadow: none;
}
.pxp-hero-form-filter.pxp-has-border .form-select {
    border: 1px solid var(--pxpLightGrayColor);
}
.pxp-hero-form-filter.pxp-has-border .form-select:focus {
    border: 1px solid var(--pxpDarkGrayColor);
}
.pxp-hero-form-filter.pxp-has-bg-color .form-select {
    border: 0 none;
    background-color: var(--pxpMainColorLight);
}
.pxp-hero-form-filter.pxp-has-bg-white .form-select {
    border: 0 none;
    background-color: #fff;
}

/**
 * 19.0 - Single job
 */

.pxp-single-job-cover {
    height: 400px;
    border-radius: 30px;
    margin-top: 80px;
}
.pxp-single-job-content.pxp-has-columns .pxp-single-job-cover {
    margin-top: 0;
}
.pxp-single-job-cover.pxp-bigger-margin {
    margin-top: 120px;
}
.pxp-single-job-cover-logo {
    width: 120px;
    height: 120px;
    border-radius: 20px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 3px solid #fff;
    margin-top: -60px;
    margin-left: 60px;
}
.pxp-single-job-cover-logo.pxp-is-center {
    margin-left: auto;
    margin-right: auto;
}
.pxp-single-job-content h1 {
    font-weight: 700;
    font-size: 34px;
    letter-spacing: -1px;
}
.pxp-single-job-content.pxp-has-columns {
    margin-top: 80px;
}
.pxp-single-job-company,
.pxp-single-job-location {
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-single-job-location {
    font-weight: 300;
}
.pxp-single-job-options {
    display: flex;
    align-items: center;
}
.pxp-single-job-options.pxp-is-center {
    justify-content: center;
}
.pxp-single-job-options .pxp-single-job-save-btn,
.pxp-single-job-options .pxp-single-job-share-btn {
    border: 1px solid var(--pxpTextColor);
    color: var(--pxpTextColor);
    text-align: center;
    height: 46px;
    width: 46px;
    border-radius: 23px;
    font-size: 15px;
    transition: var(--pxpHoverTransition);
    transition-property: color, background-color;
}
.pxp-single-job-options .pxp-single-job-save-btn:hover,
.pxp-single-job-options .pxp-single-job-share-btn:hover {
    color: #fff;
    background-color: var(--pxpTextColor);
}
.pxp-single-job-options .pxp-single-job-share-btn::after {
    content: none;
}
.pxp-single-job-options .pxp-single-job-apply-btn {
    height: 46px;
    background-color: var(--pxpMainColor);
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: color, background-color;
    padding: .375rem 1rem;
}
.pxp-single-job-options .pxp-single-job-apply-btn:hover {
    color: #fff;
    background-color: var(--pxpMainColorDark);
}
.pxp-single-job-options .dropdown-menu {
    margin-top: 7px !important;
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0px 15px 30px rgba(0,0,0,0.10);
}
.pxp-single-job-options ul.dropdown-menu > li > a {
    font-size: 14px;
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: color, opacity;
    padding: 7px 20px;
    color: var(--pxpTextColor);
}
.pxp-single-job-options ul.dropdown-menu > li > a:hover,
.pxp-single-job-options ul.dropdown-menu > li > a:focus {
    background-color: transparent;
    opacity: var(--pxpHoverOpacity);
}
.pxp-single-job-share-dropdown .dropdown-item > span {
    width: 20px;
    text-align: center;
}
.pxp-single-job-date {
    font-size: 14px;
}
.pxp-is-center .pxp-single-job-date::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: var(--pxpLightGrayColor);
    border-radius: 50%;
    margin-right: 30px;
}
.pxp-single-job-category {
    display: flex;
    align-items: center;
    text-decoration: none;
}
.pxp-single-job-category-icon {
    background-color: var(--pxpMainColorLight);
    border-radius: 10px;
    padding: 8px;
    font-size: 14px;
    line-height: 14px;
    color: var(--pxpTextColor);
    text-decoration: none;
    transition: var(--pxpHoverTransition);
    transition-property: color, background-color;
}
.pxp-single-job-category:hover .pxp-single-job-category-icon {
    background-color: var(--pxpMainColor);
    color: #fff;
}
.pxp-single-job-category-label {
    padding-left: 10px;
    font-size: 14px;
    color: var(--pxpTextColor);
    text-decoration: none;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-single-job-category:hover .pxp-single-job-category-label {
    color: var(--pxpMainColor);
}
.pxp-single-job-content-details {
    font-size: 14px;
}
.pxp-single-job-content-details h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}
.pxp-single-job-content-details p,
.pxp-single-job-content-details li {
    line-height: 1.7;
}
.pxp-single-job-content-details li {
    margin-bottom: 10px;
}
.pxp-single-job-side-panel {
    background-color: var(--pxpMainColorLight);
    padding: 30px;
    border-radius: 30px;
}
.pxp-single-job-side-info-label {
    font-size: 13px;
}
.pxp-single-job-side-info-data {
    font-weight: 500;
}
.pxp-single-job-side-company {
    display: flex;
    align-items: center;
}
.pxp-single-job-side-company-logo {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 14px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-shrink: 0;
}
.pxp-single-job-side-company-profile {
    margin-left: 10px;
}
.pxp-single-job-side-company-name {
    font-weight: 600;
}
.pxp-single-job-side-company-profile > a {
    font-size: 14px;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-single-job-side-info-social {
    display: flex;
    margin-bottom: 0;
}
.pxp-single-job-side-info-social li {
    margin-right: 20px;
}
.pxp-single-job-side-info-social li a {
    color: var(--pxpTextColor);
    font-size: 20px;
    opacity: .7;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-single-job-side-info-social li a:hover {
    color: var(--pxpMainColor);
}
.pxp-single-job-side-info-data > a {
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-subsection-h2 {
    font-weight: 700;
    font-size: 28px;
    letter-spacing: -1px;
}

/**
 * 20.0 - Companies list
 */

.pxp-companies-list-side-filter h3 {
    font-size: 18px;
    font-weight: 600;
}
.pxp-companies-list-side-filter .list-group-item {
    padding: 0;
    color: var(--pxpTextColor);
    border: 0 none;
    background-color: transparent;
}
.pxp-companies-list-side-filter .form-check-input {
    border: 0 none;
    box-shadow: 0px 6px 12px rgba(0,0,0,0.05);
}
.pxp-companies-list-side-filter .form-check-input:checked {
    background-color: var(--pxpMainColor);
}
.pxp-companies-list-side-filter .form-check-input:focus {
    box-shadow: none;
}
.pxp-companies-list-side-filter .list-group-item .badge {
    background-color: #fff;
    color: var(--pxpTextColor);
    font-weight: 600;
    opacity: .7;
}
.pxp-companies-list-side-filter .list-group-item.pxp-checked .badge {
    background-color: var(--pxpMainColor);
    color: #fff;
    opacity: 1;
}
.pxp-companies-list-side-filter {
    background-color: var(--pxpSecondaryColor);
    padding: 30px;
    border-radius: 30px;
}
.pxp-companies-list-top h2 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
}
.pxp-companies-list-top .form-select {
    background-color: transparent;
    border: 0 none;
    padding: 0;
    font-weight: 500;
    color: var(--pxpTextColor);
    padding: 14px 20px;
    background-position: right center;
    font-size: 15px;
}
.pxp-companies-list-top .form-select:focus {
    box-shadow: none;
}
.pxp-companies-list-side-filter .input-group {
    box-shadow: 0px 6px 12px rgba(0,0,0,0.05);
    border-radius: 30px;
}
.pxp-companies-list-side-filter .input-group .input-group-text {
    color: var(--pxpTextColor);
    border: 0 none;
    background-color: #fff;
    border-radius: 30px;
    padding: 1rem 2px 1rem 20px;
}
.pxp-companies-list-side-filter .input-group .input-group-text > span {
    opacity: .7;
}
.pxp-companies-list-side-filter .form-control,
.pxp-companies-list-side-filter .form-select {
    border: 0 none;
    font-weight: 300;
    color: var(--pxpTextColor);
    background-color: #fff;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 1rem .75rem;
}
.pxp-companies-list-side-filter .form-select {
    background-position: right 20px center;
}
.pxp-companies-list-side-filter .form-control:focus,
.pxp-companies-list-side-filter .form-select:focus {
    box-shadow: none;
}

/**
 * 21.0 - Single company
 */

.pxp-single-company-hero {
    background-color: var(--pxpMainColorLight);
    height: 460px;
    position: relative;
}
.pxp-single-company-hero.pxp-boxed {
    border-radius: 30px;
    overflow: hidden;
}
.pxp-single-company-hero-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60px;
    z-index: 2;
}
.pxp-single-company-hero.pxp-boxed .pxp-single-company-hero-caption {
    bottom: 30px;
    left: 30px;
    right: 30px;
}
.pxp-single-company-hero-logo {
    width: 120px;
    height: 120px;
    border-radius: 20px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}
.pxp-single-company-hero-content {
    display: flex;
    align-items: center;
}
.pxp-single-company-hero-logo {
    flex-shrink: 0;
}
.pxp-single-company-hero-title {
    margin-left: 20px;
}
.pxp-single-company-hero-title h1 {
    font-weight: 700;
    font-size: 34px;
    letter-spacing: -1px;
    color: #fff;
}
.pxp-single-company-hero-location {
    color: #fff;
}
.pxp-single-company-hero-location > span {
    margin-right: 10px;
}
.pxp-single-company-content {
    font-size: 14px;
}
.pxp-single-company-content h2 {
    font-weight: 700;
    font-size: 28px;
    letter-spacing: -1px;
    margin-bottom: 30px;
}
.pxp-single-company-content p {
    line-height: 1.7;
}
.pxp-single-company-side-panel {
    background-color: var(--pxpSecondaryColorLight);
    padding: 30px;
    border-radius: 30px;
}
.pxp-single-company-side-info-label {
    font-size: 13px;
}
.pxp-single-company-side-info-data {
    font-weight: 500;
}
.pxp-single-company-side-info-social {
    display: flex;
    margin-bottom: 0;
}
.pxp-single-company-side-info-social li {
    margin-right: 20px;
}
.pxp-single-company-side-info-social li a {
    color: var(--pxpTextColor);
    font-size: 20px;
    opacity: .7;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-single-company-side-info-social li a:hover {
    color: var(--pxpMainColor);
}
.pxp-single-company-side-info-data > a {
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-single-company-gallery-item {
    display: block;
    background-color: var(--pxpSecondaryColor);
    border-radius: 30px;
    margin-top: 30px;
    overflow: hidden;
    height: 240px;
    position: relative;
}
.pxp-single-company-gallery-item.pxp-is-tall {
    height: 510px;
}
.pxp-single-company-gallery-item-fig {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 30px;
    transition: var(--pxpHoverTransition);
    transition-property: transform;
    z-index: 2;
}
.pxp-single-company-gallery-item:hover .pxp-single-company-gallery-item-fig {
    transform: translateY(-38px);
}
.pxp-single-company-gallery-item-caption {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    text-align: center;
    font-size: 12px;
    color: var(--pxpTextColor);
    z-index: 1;

    transition: var(--pxpHoverTransition);
    transition-property: transform, opacity;
    transform: translateY(10px);
    opacity: 0;
}
.pxp-single-company-gallery-item:hover .pxp-single-company-gallery-item-caption {
    transform: translateY(0);
    opacity: 1;
}
.pxp-single-company-side-panel h3 {
    font-size: 18px;
    font-weight: 600;
}
.pxp-single-company-side-panel .form-control, 
.pxp-single-company-side-panel .form-select {
    border: 1px solid rgba(0,0,0,.2);
    font-weight: 300;
    color: var(--pxpTextColor);
    background-color: #fff;
    border-radius: 30px;
    padding: 1rem;
}
.pxp-single-company-side-panel .form-select {
    background-position: right 20px center;
}
.pxp-single-company-side-panel .form-control:focus,
.pxp-single-company-side-panel .form-select:focus {
    box-shadow: none;
    border: 1px solid rgba(0,0,0,.4);
}
.pxp-single-company-side-panel .form-label {
    font-size: 13px;
    font-weight: 500;
}
.pxp-single-company-side-panel textarea {
    height: 200px;
}
.pxp-single-company-content iframe {
    border-radius: 30px;
    width: 100%;
    height: 315px;
    border: 0 none;
}
.pxp-single-company-container {
    margin-top: 120px;
}
.pxp-single-company-container.pxp-has-columns {
    margin-top: 80px;
}

/**
 * 22.0 - Company dashborad
 */

.pxp-dashboard-side-panel {
    position: fixed;
    width: 320px;
    top: 0;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    padding: 30px;
}
.pxp-dashboard-side-panel .pxp-logo {
    margin-left: 1.4rem;
}
.pxp-dashboard-side-panel nav > ul > li > a,
.pxp-nav-mobile-container.pxp-is-dashboard .pxp-nav-mobile > ul > li > a {
    padding: 1rem 1.4rem;
    border-radius: 30px;
    color: var(--pxpTextColor);
    font-weight: 500;
    display: block;
    text-decoration: none;
    transition: var(--pxpHoverTransition);
    transition-property: color, opacity;
    font-size: 15px;
    line-height: 18px;
}
.pxp-nav-mobile-container.pxp-is-dashboard .pxp-nav-mobile > ul > li > a {
    padding: 1rem 0;
}
.pxp-dashboard-side-panel nav > ul > li.pxp-active > a {
    background-color: var(--pxpMainColorDark);
    color: #fff
}
.pxp-dashboard-side-panel nav > ul > li:not(.pxp-active) > a:hover {
    color: var(--pxpMainColor);
}
.pxp-dashboard-side-panel nav > ul > li > a span:not(.badge),
.pxp-nav-mobile-container.pxp-is-dashboard .pxp-nav-mobile > ul > li > a span:not(.badge) {
    width: 27px;
    font-size: 18px;
}
.pxp-dashboard-side-panel nav > ul > li > a span.badge,
.pxp-nav-mobile-container.pxp-is-dashboard .pxp-nav-mobile > ul > li > a span.badge {
    background-color: var(--pxpMainColor);
}
.pxp-dashboard-side-panel nav > ul > li.pxp-active > a span.badge {
    background-color: #fff;
    color: var(--pxpMainColor);
}
.pxp-dashboard-side-label,
.pxp-nav-mobile-container.pxp-is-dashboard .pxp-nav-mobile .pxp-dropdown-header {
    padding: 1.4rem;
    font-size: 14px;
    opacity: .7;
}
.pxp-nav-mobile-container.pxp-is-dashboard .pxp-nav-mobile .pxp-dropdown-header {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0 1.4rem 0;
    font-weight: 300;
}
.pxp-dashboard-side-user-nav-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 320px;
    background-color: var(--pxpMainColorLight);
    padding: 30px;
}
.pxp-dashboard-side-user-nav {
    padding: 0 1.4rem;
}
.pxp-dashboard-side-user-nav-avatar {
    width: 36px;
    height: 36px;
    border-radius: 18px;
}
.pxp-dashboard-side-user-nav-dropdown > .dropdown-toggle {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--pxpTextColor);
}
.pxp-dashboard-side-user-nav-dropdown > .dropdown-toggle::after {
    margin-left: 7px;
    position: absolute;
    right: 0;
}
.pxp-dashboard-side-user-nav-name {
    margin-left: 7px;
    transition: var(--pxpHoverTransition);
    transition-property: opacity;
    color: var(--pxpTextColor);
}
.pxp-dashboard-side-user-nav-dropdown > .dropdown-toggle:hover .pxp-user-nav-name {
    opacity: .7;
}
.pxp-dashboard-side-user-nav-dropdown .dropdown-menu {
    margin-top: 7px !important;
    border: 0 none;
    border-radius: 10px;
    box-shadow: 0px 15px 30px rgba(0,0,0,0.10);
    width: 100%;
}
.pxp-dashboard-side-user-nav-dropdown ul.dropdown-menu > li > a {
    font-size: 14px;
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: opacity;
    padding: 7px 20px;
    color: var(--pxpTextColor);
}
.pxp-dashboard-side-user-nav-dropdown ul.dropdown-menu > li > a:hover,
.pxp-dashboard-side-user-nav-dropdown ul.dropdown-menu > li > a:focus {
    background-color: transparent;
    opacity: var(--pxpHoverOpacity);
    color: var(--pxpTextColor);
}
.pxp-dashboard-content {
    margin-left: 320px;
}
.pxp-dashboard-content-header {
    padding: 30px;
}
.pxp-dashboard-content-header .pxp-user-nav {
    justify-content: flex-end;
}
.pxp-user-nav-dropdown.pxp-user-notifications > .dropdown-toggle {
    width: 36px;
    height: 36px;
    font-size: 18px;
    justify-content: center;
}
.pxp-user-nav-dropdown.pxp-user-notifications > .dropdown-toggle::after {
    content: none;
}
.pxp-user-nav-dropdown.pxp-user-notifications ul.dropdown-menu > li > .dropdown-divider {
    border-top: 1px solid var(--pxpDarkGrayColor);
}
.pxp-user-nav-dropdown.pxp-user-notifications ul.dropdown-menu > li > a {
    font-weight: 300;
}
.pxp-user-nav-dropdown.pxp-user-notifications ul.dropdown-menu > li > a.pxp-link {
    font-weight: 600;
    color: var(--pxpMainColor);
}
.pxp-user-nav-dropdown.pxp-user-notifications ul.dropdown-menu > li > a .pxp-is-time {
    opacity: .5;
    font-size: 11px;
    margin-left: 5px;
}
.pxp-user-notifications-counter {
    background-color: var(--pxpMainColor);
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50rem;
    padding: 3px 5px;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
}
.pxp-dashboard-content-details {
    background-color: #fff;
    border-radius: 30px;
    margin-right: 30px;
    box-shadow: 0px 6px 12px rgba(0,0,0,0.05);
    padding: 60px;
    min-height: calc(100vh - 184px);
}
.pxp-dashboard-stats-card {
    border-radius: 30px;
    padding: 30px;
}
.pxp-dashboard-content-details h1 {
    font-size: 38px;
    letter-spacing: -2px;
    font-weight: 700;
}
.pxp-dashboard-stats-card {
    display: flex;
    padding: 30px;
    align-items: center;
}
.pxp-dashboard-stats-card-icon {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.04);
    font-size: 42px;
    width: 100px;
    height: 100px;
    text-align: center;
    color: var(--pxpTextColor);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.pxp-dashboard-stats-card-info {
    padding-left: 20px;
}
.pxp-dashboard-stats-card-info-number {
    font-weight: 600;
    font-size: 42px;
    letter-spacing: -2px;
    line-height: 42px;
}
.pxp-dashboard-stats-card-info-text {
    font-size: 14px;
}
.pxp-dashboard-content footer {
    padding: 30px 0;
    margin-right: 30px;
}
.pxp-dashboard-content .pxp-footer-copyright {
    font-size: 14px;
}
.pxp-dashboard-content-details h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 30px;
    letter-spacing: -1px;
}
.pxp-dashboard-chart-container {
    border-radius: 30px;
    border: 1px solid var(--pxpLightGrayColor);
    padding: 30px;
}
.pxp-dashboard-chart-value {
    font-weight: 600;
    font-size: 42px;
    letter-spacing: -2px;
    line-height: 42px;
}
.pxp-dashboard-chart-percent,
.pxp-dashboard-chart-vs {
    margin-left: 30px;
    font-size: 14px;
}
.pxp-dashboard-chart-container .form-select {
    background-color: transparent;
    border: 0 none;
    padding: 0;
    font-weight: 500;
    color: var(--pxpTextColor);
    padding: 14px 20px;
    background-position: right center;
    font-size: 15px;
}
.pxp-dashboard-chart-container .form-select:focus {
    box-shadow: none;
}
.pxp-dashboard-notifications-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pxp-dashboard-notifications-item-left {
    display: flex;
}
.pxp-dashboard-notifications-item-message {
    margin-left: 10px
}
.pxp-dashboard-notifications-item-right {
    opacity: .7;
    font-size: 14px;
}
.pxp-dashboard-notifications-item-type {
    opacity: .7;
}
.pxp-dashboard-notifications-item-message a {
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-dashboard-notifications-item-message a:hover {
    columns: var(--pxpMainColorDark);
}
.pxp-company-dashboard-messages-item {
    display: flex;
}
.pxp-company-dashboard-messages-item-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    flex-shrink: 0;
}
.pxp-company-dashboard-messages-item-name {
    font-weight: 500;
}
.pxp-company-dashboard-messages-item-date {
    font-size: 12px;
}
.pxp-company-dashboard-messages-item-details p {
    font-size: 14px;
}
.pxp-company-dashboard-candidate-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.pxp-company-dashboard-candidate-name {
    font-weight: 600;
}
.pxp-company-dashboard-candidate-title {
    font-size: 14px;
}
.pxp-company-dashboard-candidate-location {
    font-size: 14px;
    font-weight: 500;
}
.pxp-company-dashboard-candidate-location > span {
    margin-right: 7px;
}
.pxp-dashboard-table-options ul {
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
}
.pxp-dashboard-table-options ul li {
    margin-left: 7px;
}
.pxp-dashboard-table-options button,
.pxp-dashboard-inbox-list-item-options button {
    background-color: var(--pxpMainColorLight);
    border: 0 none;
    border-radius: 5px;
    font-size: 14px;
    color: var(--pxpMainColorDark);
    width: 26px;
    height: 26px;
    text-align: center;
    transition: var(--pxpHoverTransition);
    transition-property: background-color, color;
}
.pxp-dashboard-table-options button:hover, 
.pxp-dashboard-inbox-list-item-options button:hover {
    color: #fff;
    background-color: var(--pxpMainColor);
}
.pxp-dashboard-content-details form .form-label {
    font-size: 13px;
    font-weight: 500;
}
.pxp-dashboard-content-details form .form-control, 
.pxp-dashboard-content-details form .form-select {
    border: 1px solid rgba(0,0,0,.2);
    font-weight: 300;
    color: var(--pxpTextColor);
    background-color: #fff;
    border-radius: 30px;
    padding: 1rem;
}
.pxp-dashboard-content-details form .form-control:focus, 
.pxp-dashboard-content-details form .form-select:focus {
    box-shadow: none;
    border: 1px solid rgba(0,0,0,.4);
}
.pxp-dashboard-content-details form .pxp-company-cover,
.pxp-dashboard-content-details form .pxp-company-logo {
    position: relative;
}
.pxp-dashboard-content-details form #pxp-company-cover-choose-file,
.pxp-dashboard-content-details form #pxp-company-logo-choose-file {
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
}
.pxp-dashboard-content-details form #pxp-company-cover-choose-file + label,
.pxp-dashboard-content-details form #pxp-company-logo-choose-file + label {
    display: flex;
    border: 1px dashed rgba(0,0,0,.2);
    border-radius: 30px;
    width: 100%;
    height: 166px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
}
.pxp-dashboard-content-details form #pxp-company-logo-choose-file + label {
    width: 87px;
    height: 87px;
}
// .pxp-dashboard-content-details form textarea {
//     height: 300px;
// }
.pxp-dashboard-content-details form textarea.pxp-smaller {
    height: 150px;
}
.pxp-dashboard-content-details .form-check-input:checked {
    background-color: var(--pxpMainColor);
}
.pxp-dashboard-content-details .form-check-input:focus {
    box-shadow: none;
}
.pxp-company-dashboard-jobs-bulk-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.pxp-company-dashboard-jobs-search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pxp-company-dashboard-jobs-bulk-actions .form-select {
    font-weight: 300;
    border: 1px solid rgba(0,0,0,.2);
    color: var(--pxpTextColor);
    font-size: 15px;
    border-radius: 30px;
}
.pxp-company-dashboard-jobs-bulk-actions .form-select:focus {
    box-shadow: none;
    border: 1px solid rgba(0,0,0,.4);
}
.pxp-company-dashboard-jobs-bulk-actions .btn {
    background-color: var(--pxpMainColorLight);
    border-radius: 30px;
    font-size: 15px;
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: background-color, color;
    color: var(--pxpMainColorDark);
}
.pxp-company-dashboard-jobs-bulk-actions .btn:hover {
    color: #fff;
    background-color: var(--pxpMainColor);
}
.pxp-dashboard-content-details .table-hover > tbody > tr {
    transition: var(--pxpHoverTransition);
    transition-property: background-color;
    color: var(--pxpTextColor);
}
.pxp-dashboard-content-details .table-hover > tbody > tr:hover {
    background-color: var(--pxpSecondaryColorLight);
    --bs-table-accent-bg: none;
}
.pxp-company-dashboard-jobs-search-results {
    font-weight: 600;
}
.pxp-company-dashboard-jobs-search-search-form .input-group .input-group-text {
    padding-right: 1px;
    color: var(--pxpTextColor);
    border-right: 0 none;
    background-color: transparent;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-color: rgba(0,0,0,.2);
    font-size: 15px;
}
.pxp-company-dashboard-jobs-search-search-form .input-group .input-group-text span {
    opacity: .7;
}
.pxp-company-dashboard-jobs-search-search-form .form-control {
    border-left: 0 none;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-color: rgba(0,0,0,.2);
    font-size: 15px;
    font-weight: 300;
    color: var(--pxpTextColor);
}
.pxp-company-dashboard-jobs-search-search-form .form-control:focus {
    box-shadow: none;
}
.pxp-dashboard-content-details .table-hover>:not(:last-child)>:last-child>* {
    border-bottom-color: #dee2e6;
}
.pxp-dashboard-content-details .table-hover th:not(.pxp-is-checkbox) {
    color: rgba(0,0,0,.5);
    font-weight: 500;
    font-size: 14px;
}
.pxp-dashboard-content-details .table .pxp-company-dashboard-job-title {
    font-weight: 600;
}
.pxp-dashboard-content-details .table .pxp-company-dashboard-job-location {
    color: var(--pxpTextColor);
    font-size: 13px;
    opacity: .7;
}
.pxp-company-dashboard-job-category,
.pxp-company-dashboard-job-type,
.pxp-company-dashboard-job-applications {
    font-size: 14px;
}
.pxp-company-dashboard-job-type {
    font-weight: 500;
}
.pxp-company-dashboard-job-date {
    font-size: 13px;
}
.pxp-pagination {
    margin-bottom: 0;
}
.pxp-pagination.pxp-dashboard-pagination .page-link {
    color: var(--pxpMainColor);
    border-color: var(--pxpMainColorLight);
    padding: 7px 14px;
    font-size: 14px;
    font-weight: 500;
}
.pxp-pagination.pxp-dashboard-pagination .page-link:hover {
    color: #fff;
    border-color: var(--pxpMainColor);
    background-color: var(--pxpMainColor);
}
.pxp-dashboard-pagination-btn {
    background-color: var(--pxpMainColor);
    border-radius: 30px;
    font-size: 15px;
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: background-color, color;
    color: #fff;
}
.pxp-dashboard-pagination-btn:hover {
    background-color: var(--pxpMainColorDark);
    color: #fff;
}
.pxp-company-dashboard-job-status .badge {
    font-weight: 500;
}
.pxp-company-dashboard-subscriptions-plan {
    font-weight: 600;
}
.pxp-dashboard-inbox-search-form .input-group .input-group-text {
    padding-right: 1px;
    color: var(--pxpTextColor);
    border-right: 0 none;
    background-color: transparent;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-color: rgba(0,0,0,.2);
    font-size: 15px;
    padding-left: 20px;
}
.pxp-dashboard-inbox-search-form .input-group .input-group-text span {
    opacity: .7;
}
.pxp-dashboard-inbox-search-form .form-control {
    border-left: 0 none;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-color: rgba(0,0,0,.2);
    font-size: 15px;
    font-weight: 300;
    color: var(--pxpTextColor);
    padding: 1rem;
}
.pxp-dashboard-inbox-search-form .form-control:focus {
    box-shadow: none;
}
.pxp-dashboard-inbox-list {
    height: 700px;
    overflow-y: auto;
}
.pxp-dashboard-inbox-list ul {
    margin-bottom: 0;
}
.pxp-dashboard-inbox-list-item {
    display: block;
    padding: 30px;
    border-radius: 30px;
}
.pxp-dashboard-inbox-list ul li.pxp-active .pxp-dashboard-inbox-list-item {
    background-color: var(--pxpMainColorTransparent);
}
.pxp-dashboard-inbox-list-item-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pxp-dashboard-inbox-list-item-left {
    display: flex;
    align-items: center;
}
.pxp-dashboard-inbox-list-item-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
}
.pxp-dashboard-inbox-list-item-name {
    font-weight: 600;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-dashboard-inbox-list ul li:not(.pxp-active) .pxp-dashboard-inbox-list-item:hover .pxp-dashboard-inbox-list-item-name {
    color: var(--pxpMainColor);
}
.pxp-dashboard-inbox-list-item-time {
    color: var(--pxpTextColor);
    font-size: 13px;
}
.pxp-dashboard-inbox-list-item-text {
    font-size: 13px;
    color: var(--pxpTextColor);
}
.pxp-dashboard-inbox-list-item-no .badge {
    background-color: var(--pxpMainColor);
}
.pxp-dashboard-inbox-messages-container {
    border-radius: 30px;
    border: 1px solid var(--pxpLightGrayColor);
    height: 780px;
    position: relative;
    overflow: hidden;
}
.pxp-dashboard-inbox-messages-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 6px 12px rgba(0,0,0,0.05);
    background-color: #fff;
    z-index: 2;
}
.pxp-dashboard-inbox-messages-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 130px 30px;
}
.pxp-dashboard-inbox-messages-item-header {
    display: flex;
    align-items: center;
}
.pxp-dashboard-inbox-messages-item-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
}
.pxp-dashboard-inbox-messages-item-name {
    font-weight: 600;
}
.pxp-dashboard-inbox-messages-item-time {
    font-size: 13px;
}
.pxp-dashboard-inbox-messages-item-message {
    border-radius: 30px;
    font-size: 14px;
    padding: 30px;
}
.pxp-dashboard-inbox-messages-item-message.pxp-is-other {
    background-color: var(--pxpMainColorTransparent);
    border-top-left-radius: 0;
}
.pxp-dashboard-inbox-messages-item-message.pxp-is-self {
    background-color: var(--pxpSecondaryColorLight);
    border-top-right-radius: 0;
}
.pxp-dashboard-inbox-messages-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 30px;
    display: flex;
    align-items: center;
    box-shadow: 0px -6px 12px rgba(0,0,0,0.05);
    background-color: #fff;
    z-index: 2;
}
.pxp-dashboard-inbox-messages-footer-field .form-control {
    border: 0 none;
    font-size: 15px;
    font-weight: 300;
    color: var(--pxpTextColor);
    padding: 1rem;
}
.pxp-dashboard-inbox-messages-footer-field .form-control:focus {
    box-shadow: none;
}
.pxp-dashboard-inbox-messages-footer-field {
    width: 80%;
}
.pxp-dashboard-inbox-messages-footer-btn {
    width: 20%;
}
.pxp-dashboard-inbox-messages-footer-btn .btn {
    width: 100%;
}

/**
 * 23.0 - Candidates list
 */

.pxp-candidates-list-side-filter h3 {
    font-size: 18px;
    font-weight: 600;
}
.pxp-candidates-list-side-filter .list-group-item {
    padding: 0;
    color: var(--pxpTextColor);
    border: 0 none;
    background-color: transparent;
}
.pxp-candidates-list-side-filter .form-check-input {
    border: 0 none;
    box-shadow: 0px 6px 12px rgba(0,0,0,0.05);
}
.pxp-candidates-list-side-filter .form-check-input:checked {
    background-color: var(--pxpMainColor);
}
.pxp-candidates-list-side-filter .form-check-input:focus {
    box-shadow: none;
}
.pxp-candidates-list-side-filter .list-group-item .badge {
    background-color: #fff;
    color: var(--pxpTextColor);
    font-weight: 600;
    opacity: .7;
}
.pxp-candidates-list-side-filter .list-group-item.pxp-checked .badge {
    background-color: var(--pxpMainColor);
    color: #fff;
    opacity: 1;
}
.pxp-candidates-list-side-filter {
    background-color: var(--pxpSecondaryColor);
    padding: 30px;
    border-radius: 30px;
}
.pxp-candidates-list-top h2 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
}
.pxp-candidates-list-top .form-select {
    background-color: transparent;
    border: 0 none;
    padding: 0;
    font-weight: 500;
    color: var(--pxpTextColor);
    padding: 14px 20px;
    background-position: right center;
    font-size: 15px;
}
.pxp-candidates-list-top .form-select:focus {
    box-shadow: none;
}
.pxp-candidates-list-side-filter .input-group {
    box-shadow: 0px 6px 12px rgba(0,0,0,0.05);
    border-radius: 30px;
}
.pxp-candidates-list-side-filter .input-group .input-group-text {
    color: var(--pxpTextColor);
    border: 0 none;
    background-color: #fff;
    border-radius: 30px;
    padding: 1rem 2px 1rem 20px;
}
.pxp-candidates-list-side-filter .input-group .input-group-text > span {
    opacity: .7;
}
.pxp-candidates-list-side-filter .form-control,
.pxp-candidates-list-side-filter .form-select {
    border: 0 none;
    font-weight: 300;
    color: var(--pxpTextColor);
    background-color: #fff;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 1rem .75rem;
}
.pxp-candidates-list-side-filter .form-select {
    background-position: right 20px center;
}
.pxp-candidates-list-side-filter .form-control:focus,
.pxp-candidates-list-side-filter .form-select:focus {
    box-shadow: none;
}

/**
 * 24.0 - Single candidate
 */

.pxp-single-candidate-hero {
    background-color: var(--pxpMainColorLight);
    height: 460px;
    position: relative;
}
.pxp-single-candidate-hero.pxp-boxed {
    border-radius: 30px;
    overflow: hidden;
}
.pxp-single-candidate-hero-caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60px;
    z-index: 2;
}
.pxp-single-candidate-hero.pxp-boxed .pxp-single-candidate-hero-caption {
    bottom: 30px;
    left: 30px;
}
.pxp-single-candidate-hero-avatar {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
}
.pxp-single-candidate-hero-content {
    display: flex;
    align-items: center;
}
.pxp-single-candidate-hero-name {
    margin-left: 20px;
}
.pxp-single-candidate-hero-name h1 {
    font-weight: 700;
    font-size: 34px;
    letter-spacing: -1px;
    color: #fff;
}
.pxp-single-candidate-hero-title {
    color: #fff;
}
.pxp-single-candidate-content {
    font-size: 14px;
}
.pxp-single-candidate-content h2 {
    font-weight: 700;
    font-size: 28px;
    letter-spacing: -1px;
    margin-bottom: 30px;
}
.pxp-single-candidate-content p {
    line-height: 1.7;
}
.pxp-single-candidate-side-panel {
    background-color: var(--pxpSecondaryColorLight);
    padding: 30px;
    border-radius: 30px;
}
.pxp-single-candidate-side-info-label {
    font-size: 13px;
}
.pxp-single-candidate-side-info-data {
    font-weight: 500;
}
.pxp-single-candidate-skills ul {
    margin-bottom: 0;
}
.pxp-single-candidate-skills ul li {
    background-color: var(--pxpMainColorTransparent);
    color: var(--pxpMainColorDark);
    font-size: 13px;
    display: inline-block;
    border-radius: 50px;
    padding: 7px 17px;
    text-decoration: none;
    margin-right: 5px;
    margin-bottom: 10px;
}
.pxp-single-candidate-timeline-item {
    display: flex;
    position: relative;
}
.pxp-single-candidate-timeline-dot {
    width: 3px;
    height: 3px;
    background-color: #fff;
    border: 4px solid var(--pxpMainColor);
    border-radius: 50%;
    box-sizing: content-box;
    flex-shrink: 0;
    margin-top: 6px;
}
.pxp-single-candidate-timeline-dot::before {
    content: "";
    width: 1px;
    position: absolute;
    top: -1px;
    height: 7px;
    left: 5px;
    border-right: 1px dotted var(--pxpMainColor);
}
.pxp-single-candidate-timeline-dot::after {
    content: "";
    width: 1px;
    bottom: 0;
    position: absolute;
    top: 15px;
    left: 5px;
    border-right: 1px dotted var(--pxpMainColor);
}
.pxp-single-candidate-timeline-item:first-child .pxp-single-candidate-timeline-dot:before {
    content: none;
}
.pxp-single-candidate-timeline-item:last-child .pxp-single-candidate-timeline-dot:after {
    content: none;
}
.pxp-single-candidate-timeline-position {
    font-size: 16px;
    font-weight: 600;
}
.pxp-single-candidate-timeline-time span {
    display: inline-block;
    font-size: 13px;
    font-weight: 300;
    background-color: var(--pxpMainColorLight);
    color: var(--pxpMainColorDark);
    padding: 2px 8px;
    border-radius: 20px;
    width: 96px;
    text-align: center;
}
.pxp-single-candidate-timeline-company {
    font-weight: 500;
}
.pxp-single-candidate-side-panel h3 {
    font-size: 18px;
    font-weight: 600;
}
.pxp-single-candidate-side-panel .form-control, 
.pxp-single-candidate-side-panel .form-select {
    border: 1px solid rgba(0,0,0,.2);
    font-weight: 300;
    color: var(--pxpTextColor);
    background-color: #fff;
    border-radius: 30px;
    padding: 1rem;
}
.pxp-single-candidate-side-panel .form-select {
    background-position: right 20px center;
}
.pxp-single-candidate-side-panel .form-control:focus,
.pxp-single-candidate-side-panel .form-select:focus {
    box-shadow: none;
    border: 1px solid rgba(0,0,0,.4);
}
.pxp-single-candidate-side-panel .form-label {
    font-size: 13px;
    font-weight: 500;
}
.pxp-single-candidate-side-panel textarea {
    height: 200px;
}
.pxp-single-candidate-container {
    margin-top: 120px;
}
.pxp-single-candidate-container.pxp-has-columns {
    margin-top: 80px;
}

/**
 * 25.0 - Candidate dashboard
 */

.pxp-dashboard-side-user-nav-container.pxp-is-candidate {
    background-color: var(--pxpSecondaryColorLight);
}
.pxp-candidate-dashboard-notifications-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pxp-candidate-dashboard-notifications-item-left {
    display: flex;
}
.pxp-candidate-dashboard-notifications-item-message {
    margin-left: 10px
}
.pxp-candidate-dashboard-notifications-item-right {
    opacity: .7;
    font-size: 14px;
}
.pxp-candidate-dashboard-notifications-item-type {
    opacity: .7;
}
.pxp-candidate-dashboard-notifications-item-message a {
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-candidate-dashboard-notifications-item-message a:hover {
    columns: var(--pxpMainColorDark);
}
.pxp-candidate-dashboard-messages-item {
    display: flex;
}
.pxp-candidate-dashboard-messages-item-avatar {
    width: 60px;
    height: 60px;
    border-radius: 14px;
    flex-shrink: 0;
}
.pxp-candidate-dashboard-messages-item-name {
    font-weight: 500;
}
.pxp-candidate-dashboard-messages-item-date {
    font-size: 12px;
}
.pxp-candidate-dashboard-messages-item-details p {
    font-size: 14px;
}
.pxp-candidate-dashboard-job-avatar {
    width: 40px;
    height: 40px;
    border-radius: 10px;
}
.pxp-candidate-dashboard-job-title {
    font-weight: 600;
}
.pxp-candidate-dashboard-job-company {
    font-size: 13px;
}
.pxp-candidate-dashboard-job-category {
    font-size: 14px;
}
.pxp-candidate-dashboard-job-location {
    font-size: 14px;
    font-weight: 500;
}
.pxp-candidate-dashboard-job-location > span {
    margin-right: 7px;
}
.pxp-candidate-dashboard-job-type {
    font-size: 14px;
}
.pxp-dashboard-content-details form .pxp-candidate-cover,
.pxp-dashboard-content-details form .pxp-candidate-photo {
    position: relative;
}
.pxp-dashboard-content-details form #pxp-candidate-cover-choose-file,
.pxp-dashboard-content-details form #pxp-candidate-photo-choose-file {
    height: 0;
    width: 0;
    overflow: hidden;
    position: absolute;
}
.pxp-dashboard-content-details form #pxp-candidate-cover-choose-file + label,
.pxp-dashboard-content-details form #pxp-candidate-photo-choose-file + label {
    display: flex;
    border: 1px dashed rgba(0,0,0,.2);
    border-radius: 30px;
    width: 100%;
    height: 166px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
}
.pxp-dashboard-content-details form #pxp-candidate-photo-choose-file + label {
    width: 87px;
    height: 87px;
}
.pxp-candidate-dashboard-skills ul {
    margin-bottom: 0;
}
.pxp-candidate-dashboard-skills ul li {
    background-color: var(--pxpMainColorTransparent);
    color: var(--pxpMainColorDark);
    font-size: 13px;
    display: inline-block;
    border-radius: 50px;
    padding: 7px 17px;
    text-decoration: none;
    margin-right: 5px;
    margin-bottom: 10px;
}
.pxp-candidate-dashboard-skills ul li span {
    margin-left: 10px;
    cursor: pointer;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-candidate-dashboard-skills ul li span:hover {
    color: var(--pxpMainColor);
}
.pxp-dashboard-content-details form .input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding-left: 1rem;
    padding-right: 1rem;
}
.pxp-dashboard-content-details form .btn:focus {
    box-shadow: none;
}
.pxp-dashboard-content-details form .input-group .btn {
    background-color: var(--pxpMainColorLight);
    color: var(--pxpMainColorDark);
    transition: var(--pxpHoverTransition);
    transition-property: background-color, color;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid rgba(0,0,0,.2);
}
.pxp-dashboard-content-details form .input-group .btn:hover {
    color: #fff;
    background-color: var(--pxpMainColor);
}
.pxp-candidate-dashboard-experience-title {
    font-weight: 600;
}
.pxp-candidate-dashboard-experience-company,
.pxp-candidate-dashboard-experience-school {
    font-size: 14px;
}
.pxp-candidate-dashboard-experience-time {
    font-size: 14px;
}
.pxp-dashboard-content-details .pxp-subsection-cta {
    font-size: 15px;
    font-weight: 500;
    padding: 1rem 1.2rem;
}
.pxp-candidate-dashboard-jobs-bulk-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.pxp-candidate-dashboard-jobs-search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.pxp-candidate-dashboard-jobs-bulk-actions .form-select {
    font-weight: 300;
    border: 1px solid rgba(0,0,0,.2);
    color: var(--pxpTextColor);
    font-size: 15px;
    border-radius: 30px;
}
.pxp-candidate-dashboard-jobs-bulk-actions .form-select:focus {
    box-shadow: none;
    border: 1px solid rgba(0,0,0,.4);
}
.pxp-candidate-dashboard-jobs-bulk-actions .btn {
    background-color: var(--pxpMainColorLight);
    border-radius: 30px;
    font-size: 15px;
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: background-color, color;
    color: var(--pxpMainColorDark);
}
.pxp-candidate-dashboard-jobs-bulk-actions .btn:hover {
    color: #fff;
    background-color: var(--pxpMainColor);
}
.pxp-candidate-dashboard-jobs-search-results {
    font-weight: 600;
}
.pxp-candidate-dashboard-jobs-search-search-form .input-group .input-group-text {
    padding-right: 1px;
    color: var(--pxpTextColor);
    border-right: 0 none;
    background-color: transparent;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-color: rgba(0,0,0,.2);
    font-size: 15px;
}
.pxp-candidate-dashboard-jobs-search-search-form .input-group .input-group-text span {
    opacity: .7;
}
.pxp-candidate-dashboard-jobs-search-search-form .form-control {
    border-left: 0 none;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    border-color: rgba(0,0,0,.2);
    font-size: 15px;
    font-weight: 300;
    color: var(--pxpTextColor);
}
.pxp-candidate-dashboard-jobs-search-search-form .form-control:focus {
    box-shadow: none;
}
.pxp-dashboard-content-details .table .pxp-candidate-dashboard-job-title {
    font-weight: 600;
}
.pxp-dashboard-content-details .table .pxp-candidate-dashboard-job-location {
    color: var(--pxpTextColor);
    font-size: 13px;
    opacity: .7;
}
.pxp-candidate-dashboard-job-category,
.pxp-candidate-dashboard-job-type,
.pxp-candidate-dashboard-job-company {
    font-size: 14px;
}
.pxp-candidate-dashboard-job-type {
    font-weight: 500;
}
.pxp-candidate-dashboard-job-date {
    font-size: 13px;
}

/**
 * 26.0 - Bolg
 */

.pxp-blog-hero {
    padding: 60px 0;
    margin-top: 80px;
}
.pxp-blog-hero h1 {
    font-size: 48px;
    letter-spacing: -2px;
    font-weight: 700;
}
.pxp-blog-featured-posts-carousel {
    height: 460px;
}
.pxp-featured-posts-item {
    background-color: var(--pxpMainColorLight);
    height: 460px;
    position: relative;
    border-radius: 30px;
    overflow: hidden;
}
.pxp-featured-posts-item-caption {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.pxp-featured-posts-item-caption-content {
    width: 100%;
    transform: translateY(20px);
    opacity: 0;
    transition: 1s cubic-bezier(0.215,0.61,0.355,1);
    transition-property: transform, opacity;
}
.carousel-item.active .pxp-featured-posts-item-caption-content {
    transform: translateY(0);
    opacity: 1;
}
.pxp-featured-posts-item-title {
    font-weight: 700;
    font-size: 34px;
    letter-spacing: -1px;
    color: #fff;
}
.pxp-featured-posts-item-summary {
    color: #fff;
}
.pxp-featured-posts-item-date {
    font-size: 13px;
    color: #fff;
}
.pxp-blogs-list-side-panel {
    background-color: var(--pxpMainColorLight);
    padding: 30px;
    border-radius: 30px;
    margin-top: 30px;
}
.pxp-blogs-list-side-panel h3 {
    font-size: 18px;
    font-weight: 600;
}
.pxp-blogs-list-side-panel .input-group {
    box-shadow: 0px 6px 12px rgba(0,0,0,0.05);
    border-radius: 30px;
}
.pxp-blogs-list-side-panel .input-group .input-group-text {
    color: var(--pxpTextColor);
    border: 0 none;
    background-color: #fff;
    border-radius: 30px;
    padding: 1rem 2px 1rem 20px;
}
.pxp-blogs-list-side-panel .input-group .input-group-text > span {
    opacity: .7;
}
.pxp-blogs-list-side-panel .form-control,
.pxp-blogs-list-side-panel .form-select {
    border: 0 none;
    font-weight: 300;
    color: var(--pxpTextColor);
    background-color: #fff;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 1rem .75rem;
}
.pxp-blogs-list-side-panel .form-select {
    background-position: right 20px center;
}
.pxp-blogs-list-side-panel .form-control:focus,
.pxp-blogs-list-side-panel .form-select:focus {
    box-shadow: none;
}
.pxp-blogs-side-list li {
    margin: .5rem 0;
}
.pxp-blogs-side-list li > a {
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-blogs-side-list li > a:hover {
    color: var(--pxpMainColor);
}
.pxp-blogs-side-articles-list-item {
    display: flex;
    align-items: center;
}
.pxp-blogs-side-articles-list-item-title {
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
    font-weight: 500;
}
.pxp-blogs-side-articles-list-item:hover .pxp-blogs-side-articles-list-item-title {
    color: var(--pxpMainColor);
}
.pxp-blogs-side-articles-list-item-date {
    color: var(--pxpTextColor);
    font-size: 13px;
}
.pxp-blogs-side-articles-list-item-fig {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    flex-shrink: 0;
}
.pxp-blogs-side-tags > a {
    background-color: var(--pxpMainColorTransparent);
    color: var(--pxpMainColor);
    font-size: 13px;
    display: inline-block;
    border-radius: 50px;
    padding: 7px 17px;
    text-decoration: none;
    margin-right: 5px;
    margin-bottom: 10px;
    transition: var(--pxpHoverTransition);
    transition-property: background-color, color;
}
.pxp-blogs-side-tags > a:hover {
    background-color: var(--pxpMainColor);
    color: #fff;
}
.pxp-posts-card-2-container {
    margin-top: 30px;
}
.pxp-posts-card-2 {
    background-color: #fff;
    border-radius: 30px;
    text-decoration: none;
    display: flex;
    height: 260px;
}
.pxp-posts-card-2.pxp-has-shadow {
    box-shadow: 0px 10px 20px rgba(0,0,0,0.04);
}
.pxp-posts-card-2.pxp-has-border {
    border: 1px solid var(--pxpLightGrayColor);
}
.pxp-posts-card-2-fig {
    height: 260px;
    width: 340px;
    flex-shrink: 0;
    border-radius: 30px;
    margin: -1px;
}
.pxp-posts-card-2-fig .pxp-cover {
    height: 260px;
    width: 340px;
    border-radius: 30px;
}
.pxp-posts-card-2-content {
    padding: 30px;
    flex: auto;
}
.pxp-posts-card-2-category {
    font-size: 14px;
    font-weight: 500;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
    text-decoration: none;
}
.pxp-posts-card-2-category:hover {
    color: var(--pxpMainColor);
}
.pxp-posts-card-2-date {
    font-size: 13px;
}
.pxp-posts-card-2-title a {
    display: block;
    font-weight: 600;
    color: var(--pxpTextColor);
    font-size: 18px;
    transition: var(--pxpHoverTransition);
    transition-property: color;
    text-decoration: none;
}
.pxp-posts-card-2-title a:hover {
    color: var(--pxpMainColor);
}
.pxp-posts-card-2-summary {
    font-size: 14px;
    margin-top: 10px;
}
.pxp-posts-card-2-cta {
    margin-top: 30px;
}
.pxp-posts-card-2-cta > a {
    font-weight: 500;
    color: var(--pxpMainColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-posts-card-2-cta > a:hover {
    color: var(--pxpMainColorDark);
}
.pxp-posts-card-2-cta > a > span {
    margin-left: 10px;
    transition: var(--pxpHoverTransition);
    transition-property: margin-left;
}
.pxp-posts-card-2-cta > a:hover > span {
    margin-left: 20px;
}
.pxp-single-blog-featured-img {
    border-radius: 30px;
    width: 100%;
    height: auto;
}
.pxp-single-blog-top-category a,
.pxp-single-blog-top-author a {
    font-weight: 500;
    transition: var(--pxpHoverTransition);
    transition-property: color;
    font-size: 15px;
}
.pxp-single-blog-side-panel {
    background-color: var(--pxpMainColorLight);
    padding: 30px;
    border-radius: 30px;
}
.pxp-single-blog-content h2 {
    font-weight: 700;
    font-size: 24px;
    letter-spacing: -1px;
    margin-bottom: 1rem;
}
.pxp-single-blog-content img {
    width: 100%;
    height: auto;
    border-radius: 30px;
}
.pxp-single-blog-content blockquote {
    width: 80%;
    margin: 0 auto;
    font-weight: 500;
    font-size: 18px;
    font-style: italic;
}
.pxp-single-blog-content blockquote cite {
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
}
.pxp-single-blog-share {
    display: flex;
    align-items: center;
    font-weight: 500;
}
.pxp-single-blog-share ul {
    display: flex;
    margin-bottom: 0;
}
.pxp-single-blog-share ul li {
    margin-right: 30px;
}
.pxp-single-blog-share ul li a {
    color: var(--pxpTextColor);
    font-size: 20px;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-single-blog-share ul li a:hover {
    color: var(--pxpMainColor);
}
.pxp-blog-post-comments h4 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 1rem;
}
ol.pxp-comments-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.pxp-comments-list-item {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.pxp-comments-list-item > img {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    margin-right: 1rem;
}
.pxp-comments-list-item-body {
    flex: 1;
    max-width: 100%;
}
.pxp-comments-list-item-body h5 {
    font-weight: 500;
    font-size: 16px;
}
.pxp-comments-list-item-date {
    font-size: 13px;
    opacity: .7;
}
.pxp-comments-list-item-content {
    font-size: 14px;
}
.pxp-comments-logged-as {
    font-size: 14px;
}
.pxp-comments-logged-as a {
    color: var(--pxpTextColor);
    text-decoration: underline;
}
.pxp-comments-form .form-control {
    border: 1px solid rgba(0,0,0,.2);
    font-weight: 300;
    color: var(--pxpTextColor);
    background-color: #fff;
    border-radius: 30px;
    padding: 1rem;
}
.pxp-comments-form .form-control:focus {
    box-shadow: none;
    border: 1px solid rgba(0,0,0,.4);
}
.pxp-comments-form textarea {
    height: 150px;
}
.pxp-comments-form label {
    font-size: 13px;
    font-weight: 500;
}

/**
 * 27.0 - FAQs
 */

.pxp-faqs-accordion .accordion-item {
    border: 0 none;
    border-bottom: 1px solid var(--pxpLightGrayColor);
}
.pxp-faqs-accordion .accordion-item:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.pxp-faqs-accordion .accordion-button {
    padding: 1rem 0;
}
.pxp-faqs-accordion .accordion-button {
    box-shadow: none;
}
.pxp-faqs-accordion .accordion-button:not(.collapsed) {
    color: var(--pxpMainColor);
    background-color: #fff;
}
.pxp-faqs-accordion .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.pxp-faqs-accordion .accordion-body {
    padding: 1rem 0;
}

/**
 * 28.0 - Contact
 */

.pxp-contact-item-icon {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 4%);
    padding: 30px;
    display: inline-block;
    font-size: 42px;
    line-height: 42px;
    width: 103px;
    text-align: center;
}
.pxp-contact-item-text {
    font-size: 14px;
}
.pxp-contact-us-form .form-control, 
.pxp-contact-us-form .form-select {
    border: 1px solid rgba(0,0,0,.2);
    font-weight: 300;
    color: var(--pxpTextColor);
    background-color: #fff;
    border-radius: 30px;
    padding: 1rem;
}
.pxp-contact-us-form .form-select {
    background-position: right 20px center;
}
.pxp-contact-us-form .form-control:focus,
.pxp-contact-us-form .form-select:focus {
    box-shadow: none;
    border: 1px solid rgba(0,0,0,.4);
}
.pxp-contact-us-form .form-label {
    font-size: 13px;
    font-weight: 500;
}
.pxp-contact-us-form textarea {
    height: 140px;
}
.pxp-contact-us-form.pxp-has-animation {
    transition: .6s cubic-bezier(0.215,0.61,0.355,1);
    transition-property: opacity, transform;
    transform: translateX(50px);
    opacity: 0;
}
.pxp-contact-us-form.pxp-has-animation.pxp-animate {
    transform: translateX(0);
    opacity: 1;
}
.pxp-contact-card-1-container {
    margin-top: 30px;
}
.pxp-contact-card-1 {
    padding: 30px;
    background-color: var(--pxpMainColorLight);
    text-align: center;
    border-radius: 30px;
    text-decoration: none;
    transition: var(--pxpHoverTransition);
    transition-property: background-color;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.pxp-contact-card-1:hover {
    background-color: var(--pxpMainColorDark);
}
.pxp-contact-card-1-icon {
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.04);
    padding: 30px;
    display: inline-block;
    font-size: 42px;
    line-height: 42px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: background-color, color;
}
.pxp-contact-card-1:hover .pxp-contact-card-1-icon {
    background-color: var(--pxpMainColor);
    color: #fff;
}
.pxp-contact-card-1-title {
    margin-top: 30px;
    font-weight: 500;
    font-size: 18px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-contact-card-1:hover .pxp-contact-card-1-title {
    color: #fff;
}
.pxp-contact-card-1-subtitle {
    margin-top: 30px;
    font-size: 14px;
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-contact-card-1:hover .pxp-contact-card-1-subtitle {
    color: #fff;
}

/**
 * 29.0 - Sign in / Sign Uup
 */

.pxp-sign-hero-container {
    height: 100%;
}
.pxp-sign-hero-container .pxp-column {
    height: 100%;
    position: relative;
}
.pxp-sign-hero-container .pxp-is-light {
    background-color: #fff;
}
.pxp-sign-hero-fig {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.pxp-sign-hero-fig img {
    width: 30%;
}
.pxp-sign-hero-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.pxp-sign-hero-form > .row {
    width: 100%;
}
.pxp-sign-hero-form h5 {
    font-weight: 600;
    font-size: 22px;
    letter-spacing: -1px;
}
.pxp-sign-hero-form .form-control {
    border-radius: 30px;
    border: 1px solid var(--pxpLightGrayColor);
    color: var(--pxpTextColor);
    font-weight: 300;
}
.pxp-sign-hero-form .form-control:focus {
    box-shadow: none;
    color: var(--pxpTextColor);
    border-color: var(--pxpDarkGrayColor);
}
.pxp-sign-hero-form .form-floating > .form-control {
    padding: 1rem 1.3rem;
}
.pxp-sign-hero-form .form-floating > .form-control:-webkit-autofill,
.pxp-sign-hero-form .form-floating > .form-control:focus, 
.pxp-sign-hero-form .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem;
}
.pxp-sign-hero-form .form-floating > label {
    padding: 1rem 1.3rem;
}
.pxp-sign-hero-form .form-floating > span {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    opacity: .7;
}
.pxp-sign-hero-form-cta {
    display: block;
    background-color: var(--pxpMainColor);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 14px 20px;
    transition: var(--pxpHoverTransition);
    transition-property: background-color;
}
.pxp-sign-hero-form-cta:hover {
    color: #fff;
    background-color: var(--pxpMainColorDark);
}
.pxp-sign-hero-form-small {
    font-size: 14px;
}
.pxp-sign-hero-form-small a {
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-sign-hero-form-small a:hover {
    color: var(--pxpMainColorDark);
}

/**
 * 30.0 - Modals
 */

.modal-content {
    border: 0 none;
    box-shadow: 0px 15px 30px rgba(0,0,0,0.16);
    border-radius: 30px;
}
.modal-header {
    padding: 0;
    border-bottom: 0 none;
}
.modal-header .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 20px;
    margin: 0;
    z-index: 2;
    transition: var(--pxpHoverTransition);
    transition-property: opacity;
}
.modal-body {
    padding: 40px;
}
.modal-title {
    font-weight: 600;
    font-size: 22px;
    letter-spacing: -1px;
}
.pxp-user-modal .modal-title {
    padding: 0 40px;
}
.pxp-user-modal-fig img {
    height: 120px;
    width: auto;
}
.pxp-user-modal .form-control {
    border-radius: 30px;
    border: 1px solid var(--pxpLightGrayColor);
    color: var(--pxpTextColor);
    font-weight: 300;
}
.pxp-user-modal .form-control:focus {
    box-shadow: none;
    color: var(--pxpTextColor);
    border-color: var(--pxpDarkGrayColor);
}
.pxp-user-modal .form-floating > .form-control {
    padding: 1rem 1.3rem;
}
.pxp-user-modal .form-floating > .form-control:-webkit-autofill,
.pxp-user-modal .form-floating > .form-control:focus, 
.pxp-user-modal .form-floating > .form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem;
}
.pxp-user-modal .form-floating > label {
    padding: 1rem 1.3rem;
}
.pxp-user-modal .form-floating > span {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    opacity: .7;
}
.pxp-modal-cta {
    display: block;
    background-color: var(--pxpMainColor);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 14px 20px;
    transition: var(--pxpHoverTransition);
    transition-property: background-color;
}
.pxp-modal-cta:hover {
    color: #fff;
    background-color: var(--pxpMainColorDark);
}
.pxp-modal-small {
    font-size: 14px;
}
.pxp-modal-small a {
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-modal-small a:hover {
    color: var(--pxpMainColorDark);
}
.btn-close:focus {
    box-shadow: none;
}

/**
 * 31.0 - Footer
 */

.pxp-main-footer {
    font-size: 14px;
}
.pxp-footer-logo > a {
    color: var(--pxpTextColor);
    text-decoration: none;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: -1px;
    transition: var(--pxpHoverTransition);
    transition-property: opacity;
}
.pxp-footer-logo > a:hover {
    opacity: var(--pxpHoverOpacity);
}
.pxp-footer-phone {
    font-size: 22px;
    color: var(--pxpMainColor);
    font-weight: 500;
}
.pxp-main-footer h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}
.pxp-footer-list {
    padding-left: 0;
    list-style: none;
}
.pxp-footer-list > li {
    margin: .5rem 0;
}
.pxp-footer-list > li > a {
    color: var(--pxpTextColor);
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-footer-list > li > a:hover {
    color: var(--pxpMainColor);
}
.pxp-main-footer-bottom {
    padding: 60px 0;
}
.pxp-footer-social ul {
    display: flex;
    margin-bottom: 0;
}
.pxp-footer-social ul li {
    margin-left: 34px;
}
.pxp-footer-social ul li a {
    color: var(--pxpTextColor);
    font-size: 20px;
    opacity: .7;
    transition: var(--pxpHoverTransition);
    transition-property: color;
}
.pxp-footer-social ul li a:hover {
    color: var(--pxpMainColor);
}

/**
 * 32.0 - Media queries
 */

@media (min-width: 576px) {
    .pxp-container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .pxp-container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .pxp-container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .pxp-container {
        max-width: 1140px;
    }
}
@media (min-width: 1400px) {
    .pxp-container {
        max-width: 1320px;
    }
}
@media (min-width: 1680px) {
    .pxp-container {
        max-width: 1600px;
    }
}









@media screen and (max-width: 1736px) {
    .pxp-hero-boxed-content {
        margin-left: 0;
        margin-right: 0;
    }
    .pxp-hero-boxed-info-list-container {
        left: 0;
    }
    .pxp-hero-boxed-info-card-small {
        top: 14%;
    }
    .pxp-hero-boxed-icon-circle-2 {
        bottom: 140px;
        right: 200px;
    }
}
@media screen and (max-width: 1679px) {
    .pxp-hero-center-carousel {
        width: 43%;
    }
    .pxp-hero-stats-item:nth-child(1) {
        right: 16%;
    }
    .pxp-hero-stats-item:nth-child(2) {
        right: 19%;
    }
    .pxp-hero-stats-item:nth-child(3) {
        right: 22%;
    }
    .pxp-hero-boxed-content {
        margin-left: -80px;
        margin-right: -80px;
    }
    .pxp-hero-boxed-icon-circles {
        display: none;
    }
    .pxp-info-fig {
        padding-bottom: 60px;
        padding-right: 120px;
    }
    .pxp-info-fig-image, 
    .pxp-info-fig::before {
        height: 500px;
    }
    .pxp-info-fig::before {
        left: 60px;
        right: 60px;
    }
}
@media screen and (max-width: 1599px) {
    .pxp-pl-80 {
        padding-left: 0;
    }
    .pxp-hero h1 {
        font-size: 64px;
        line-height: 72px;
        letter-spacing: -2px;
    }
    .pxp-hero-card,
    .pxp-hero-card-dark,
    .pxp-hero-card-light {
        height: 46vh;
        min-height: 460px;
    }
    .pxp-hero-right-bg-card {
        width: 56%;
    }
    .pxp-dashboard-stats-card-icon {
        width: 80px;
        height: 80px;
        border-radius: 20px;
        font-size: 36px;
    }
    .pxp-dashboard-stats-card-info-number {
        font-size: 36px;
        line-height: 36px;
    }
}
@media screen and (max-width: 1456px) {
    .pxp-hero-boxed-content {
        margin-left: 0;
        margin-right: 0;
        padding: 60px;
    }
    .pxp-hero-boxed-icon-circles {
        display: block;
    }
    .pxp-hero-boxed-info-cards {
        display: none;
    }
    .pxp-dashboard-stats-card-icon {
        width: 70px;
        height: 70px;
        border-radius: 10px;
        font-size: 28px;
    }
    .pxp-dashboard-stats-card {
        padding: 20px;
    }
    .pxp-dashboard-stats-card-info {
        padding-left: 10px;
    }
    .pxp-dashboard-stats-card-info-number {
        font-size: 28px;
        line-height: 28px;
    }
}
@media screen and (max-width: 1399px) {
    .pxp-hero-boxed-icon-circles {
        display: none;
    }
    .pxp-search-container {
        width: 50%;
    }
    .pxp-testimonials-1-circles-item.pxp-item-1 {
        width: 80px;
        height: 80px;
        left: 5%;
    }
    .pxp-testimonials-1-circles-item.pxp-item-2 {
        width: 90px;
        height: 90px;
        left: 12%;
    }
    .pxp-testimonials-1-circles-item.pxp-item-3 {
        width: 160px;
        height: 160px;
    }
    .pxp-testimonials-1-circles-item.pxp-item-4 {
        width: 80px;
        height: 80px;
        top: 280px;
    }
    .pxp-testimonials-1-circles-item.pxp-item-5 {
        width: 60px;
        height: 60px;
        left: 13%;
    }
    .pxp-testimonials-1-circles-item.pxp-item-6 {
        width: 100px;
        height: 100px;
        right: 10%;
    }
    .pxp-testimonials-1-circles-item.pxp-item-7 {
        width: 70px;
        height: 70px;
        right: 5%;
    }
    .pxp-testimonials-1-circles-item.pxp-item-8 {
        width: 160px;
        height: 160px;
    }
    .pxp-testimonials-1-circles-item.pxp-item-10 {
        width: 90px;
        height: 90px;
        right: 10%;
    }
    .pxp-jobs-list-side-filter.pxp-with-details .pxp-list-side-filter-panel {
        display: none;
    }
    .pxp-dashboard-inbox-messages-container {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
        display: none;
        background-color: #fff;
        height: auto;
        border-radius: 0;
    }
    .pxp-dashboard-inbox-messages-container.pxp-show {
        display: block;
    }
    .pxp-dashboard-inbox-list {
        height: auto;
        overflow-y: visible;
    }
    .pxp-dashboard-inbox-list ul li.pxp-active .pxp-dashboard-inbox-list-item {
        background-color: #fff;
    }
    .pxp-dashboard-inbox-messages-close-btn {
        background-color: #fff;
        color: var(--pxpTextColor);
        border: 1px solid var(--pxpTextColor);
        font-size: 15px;
        font-weight: 500;
        transition: var(--pxpHoverTransition);
        transition-property: color, background-color;
        margin-left: 20px;
    }
    .pxp-dashboard-inbox-messages-close-btn:hover {
        background-color: var(--pxpTextColor);
        color: #fff;
    }
    .pxp-dashboard-inbox-list-item {
        padding: 0 0 30px 0;
    }
}
@media screen and (max-width: 1199px) {
    .pxp-hero-right-bg-card {
        display: none;
    }
    .pxp-user-nav-trigger.pxp-on-light {
        color: var(--pxpTextColor);
        border: 1px solid var(--pxpTextColor);
    }
    .pxp-user-nav-trigger:hover {
        background-color: var(--pxpTextColor);
        color: #fff;
    }
    .pxp-hero {
        height: auto !important;
        min-height: auto;
    }
    .pxp-hero-caption {
        position: relative;
        top: auto;
        transform: none;
        margin-top: 140px;
    }
    .pxp-hero-bg .pxp-hero-caption {
        padding-bottom: 60px;
    }
    .pxp-hero-logos-carousel-container {
        position: static;
        padding-bottom: 60px;
    }
    .pxp-hero-center-carousel {
        width: 52%;
    }
    .pxp-hero-stats-item:nth-child(1) {
        right: 10%;
    }
    .pxp-hero-stats-item:nth-child(2) {
        right: 13%;
    }
    .pxp-hero-stats-item:nth-child(3) {
        right: 16%;
    }
    .pxp-hero-searches-container.pxp-smaller {
        width: 100%;
    }
    .pxp-search-container {
        width: 100%;
        order: 3;
    }
    .pxp-section-h2,
    .pxp-page-header-simple h1,
    .pxp-blog-hero h1 {
        font-size: 36px;
        letter-spacing: -1px;
    }
    .pxp-posts-card-2-fig {
        width: 260px;
    }
    .pxp-posts-card-2-fig .pxp-cover {
        width: 260px;
    }
    .pxp-posts-card-2-summary {
        display: none;
    }
    .pxp-page-image-hero h1 {
        font-size: 46px;
    }
}
@media screen and (max-width: 1139px) {
    
}
@media screen and (max-width: 991px) {
    .pxp-hero h1 {
        font-size: 48px;
        line-height: 56px;
        letter-spacing: -2px;
    }
    .pxp-hero-form-round.pxp-large {
        height: auto;
        padding: 20px;
    }
    .pxp-hero-form-round.pxp-large form {
        height: auto;
    }
    .pxp-hero-form-round.pxp-large form .pxp-has-left-border {
        border-left: 0 none;
    }
    .pxp-hero-form-round.pxp-large form button {
        width: 100%;
        margin-right: 0;
    }
    .pxp-section-h2,
    .pxp-page-header-simple h1,
    .pxp-blog-hero h1 {
        font-size: calc(1.325rem + .9vw);
        letter-spacing: -1px;
    }
    .pxp-promo-img {
        border-radius: 30px;
        padding-left: 40px;
    }
    .pxp-promo-img.pb-100 {
        padding-bottom: 60px;
    }
    .pxp-promo-img.pt-100 {
        padding-top: 60px;
    }
    .pxp-info-caption-list,
    .pxp-info-caption-cta {
        margin-top: 40px;
    }
    .pxp-list-side-filter-panel {
        display: none;
    }
    .pxp-jobs-tab-content {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1030;
        overflow-y: auto;
        display: none;
    }
    .pxp-jobs-tab-content.pxp-show {
        display: block;
    }
    .pxp-jobs-tab-content .tab-pane {
        border-radius: 0;
    }
    .pxp-jobs-tab-pane-cover {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .pxp-jobs-card-4.active {
        background-color: #fff;
        border-color: var(--pxpLightGrayColor);
    }
    .pxp-jobs-card-4.active .pxp-jobs-card-4-company-logo {
        border: 0 none;
    }
    .pxp-jobs-card-4.active .pxp-jobs-card-4-title, 
    .pxp-jobs-card-4.active .pxp-jobs-card-4-location, 
    .pxp-jobs-card-4.active .pxp-jobs-card-4-type, 
    .pxp-jobs-card-4.active .pxp-jobs-card-4-company, 
    .pxp-jobs-card-4.active .pxp-jobs-card-4-date {
        color: var(--pxpTextColor);
    }
    .pxp-jobs-tab-pane-close-btn {
        position: fixed;
        top: 20px;
        right: 20px;
        z-index: 1031;
        background-color: #fff;
        color: var(--pxpTextColor);
        border: 1px solid var(--pxpTextColor);
        font-size: 15px;
        font-weight: 500;
        transition: var(--pxpHoverTransition);
        transition-property: color, background-color;
    }
    .pxp-jobs-tab-pane-close-btn:hover {
        background-color: var(--pxpTextColor);
        color: #fff;
    }
    .pxp-single-job-content h1 {
        font-size: 28px;
    }
    .pxp-dashboard-content {
        margin: 80px 0.75rem 0 0.75rem;
    }
    .pxp-dashboard-content-details {
        margin-right: 0;
    }
    .pxp-dashboard-content-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 0.75rem;
        height: 80px;
        background-color: var(--pxpMainColorLight);
        z-index: 1030;
    }
    .pxp-dashboard-content-header.pxp-is-candidate {
        background-color: var(--pxpSecondaryColorLight);
    }
    .pxp-dashboard-content-details h1 {
        font-size: 28px;
        letter-spacing: -1px;
    }
    .pxp-posts-card-2 {
        flex-direction: column;
        height: auto;
    }
    .pxp-posts-card-2-fig,
    .pxp-posts-card-2-fig .pxp-cover {
        width: 100%;
        margin: 0;
    }
    .pxp-posts-card-2-summary {
        display: block;
    }
    .pxp-hero-subtitle {
        font-size: 16px;
    }
    .pxp-single-blog-content h2 {
        font-size: 20px;
    }
    .pxp-page-image-hero h1 {
        font-size: calc(1.625rem + .9vw);
        letter-spacing: -1px;
    }
    .pxp-footer-social ul li {
        margin-left: 0;
        margin-right: 34px;
    }
}
@media screen and (max-width: 767px) {
    .mt-100 {
        margin-top: 60px;
    }
    .pxp-hero-form-round.pxp-bigger {
        height: auto;
        padding: 20px;
    }
    .pxp-hero-form-round.pxp-bigger form {
        height: auto;
    }
    .pxp-hero-form-round.pxp-bigger form .pxp-has-left-border {
        border-left: 0 none;
    }
    .pxp-hero-form-round.pxp-bigger form .form-control, 
    .pxp-hero-form-round.pxp-bigger form .form-select {
        padding-left: 0;
        padding-right: 0;
    }
    .pxp-hero-form-round.pxp-bigger .form-floating > label {
        padding-left: 0;
    }
    .pxp-hero-form-round.pxp-bigger form button {
        width: 100%;
        margin-right: 0;
    }
    .pxp-hero-boxed-content {
        padding: 40px;
        border-radius: 30px;
    }
    .pxp-hero h1 {
        font-size: 36px;
        line-height: 44px;
        letter-spacing: -1px;
    }
    .pxp-hero-caption {
        margin-top: 120px;
    }
    .pxp-testimonials-1-carousel-container {
        position: relative;
    }
    .pxp-testimonials-1-carousel-container,
    .pxp-testimonials-1-carousel-container > .row {
        height: auto;
    }
    .pxp-jobs-card-3 {
        padding: 20px;
    }
    .pxp-single-job-cover {
        height: 300px;
    }
    .pxp-single-job-cover-logo {
        width: 80px;
        height: 80px;
    }
    .pxp-single-company-hero-logo {
        width: 80px;
        height: 80px;
    }
    .pxp-single-company-hero,
    .pxp-page-image-hero {
        height: 380px;
    }
    .pxp-single-company-hero-title h1 {
        font-size: 28px;
    }
    .pxp-single-company-content h2 {
        font-size: 22px;
    }
    .pxp-subsection-h2 {
        font-size: 22px;
    }
    .pxp-dashboard-content-details h2 {
        font-size: 22px;
    }
    .pxp-dashboard-content-details {
        padding: 30px;
    }
    .pxp-company-dashboard-messages-item-avatar {
        width: 40px;
        height: 40px;
    }
    .pxp-dashboard-inbox-messages-header {
        padding: 20px;
    }
    .pxp-dashboard-inbox-messages-content {
        padding: 130px 20px;
    }
    .pxp-dashboard-inbox-messages-footer {
        padding: 20px;
    }
    .pxp-single-candidate-hero-avatar {
        width: 80px;
        height: 80px;
    }
    .pxp-single-candidate-hero {
        height: 380px;
    }
    .pxp-single-candidate-hero-name h1 {
        font-size: 28px;
    }
    .pxp-single-candidate-content h2 {
        font-size: 22px;
    }
    .pxp-dashboard-chart-container {
        padding: 20px;
    }
    .pb-100 {
        padding-bottom: 60px;
    }
    .pt-100 {
        padding-top: 60px;
    }
}
@media screen and (max-width: 575px) {
    .pxp-logo > a {
        font-size: 20px;
    }
    .pxp-nav-trigger {
        flex: 0!important;
    }
    .pxp-hero-form-round {
        height: auto;
        padding: 20px;
    }
    .pxp-hero-form-round form {
        height: auto;
    }
    .pxp-hero-form-round form .pxp-has-left-border {
        border-left: 0 none;
    }
    .pxp-hero-form-round form .form-control, 
    .pxp-hero-form-round form .form-select {
        padding-left: 0;
        padding-right: 0;
    }
    .pxp-hero-form-round.pxp-large .form-control,
    .pxp-hero-form-round.pxp-large .form-select {
        padding: .375rem .75rem;
    }
    .pxp-hero-form-round form button {
        width: 100%;
    }
    .pxp-header-right {
        display: none;
    }
    .pxp-hero-boxed-content {
        padding: 20px;
    }
    .pxp-info-stats {
        display: none;
    }
    .pxp-info-fig-image, 
    .pxp-info-fig::before {
        height: 400px;
    }
    .pxp-info-fig {
        padding-bottom: 20px;
        padding-right: 20px;
    }
    .pxp-info-fig::before {
        left: 20px;
        right: 0;
    }
    .pxp-dashboard-content-details {
        padding: 20px;
    }
    .pxp-nav-trigger {
        margin-left: 20px;
    }
    .pxp-user-nav .pxp-nav-btn {
        display: none;
    }
    .pxp-companies-list-top .form-select,
    .pxp-jobs-list-top .form-select,
    .pxp-candidates-list-top .form-select {
        padding-left: 0;
    }
    .pxp-companies-card-1.pxp-has-border {
        padding: 20px;
    }
    .pxp-hero-form-round {
        border-radius: 30px;
    }
    .pxp-companies-list-top h2,
    .pxp-jobs-list-top h2,
    .pxp-candidates-list-top h2 {
        font-size: 20px;
    }
    .pxp-companies-card-1-bottom {
        margin-top: 20px;
    }
    .pxp-jobs-card-1, 
    .pxp-jobs-card-2, 
    .pxp-jobs-card-3 {
        padding: 20px;
    }
    .pxp-jobs-card-1-company-logo, 
    .pxp-jobs-card-2-company-logo, 
    .pxp-jobs-card-3-company-logo {
        width: 60px;
        height: 60px;
    }
    .pxp-promo-img {
        padding-left: 40px;
        padding-right: 40px;
    }
    .pxp-promo-img.pt-100 {
        padding-top: 40px;
    }
    .pxp-promo-img.pb-100 {
        padding-bottom: 40px;
    }
    .pxp-companies-card-1-company-logo {
        width: 60px;
        height: 60px;
    }
    .pxp-posts-card-1-info {
        padding: 20px;
    }
    .pxp-posts-card-1-content {
        padding: 20px 20px 0;
    }
    .pxp-posts-card-1-cta {
        padding: 0 20px 20px;
    }
    .pxp-posts-card-1-bottom {
        margin-top: 20px;
    }
    .pxp-cities-card-2-info {
        padding: 20px;
    }
    .pxp-candiadates-card-1 {
        padding: 20px;
    }
    .pxp-candiadates-card-1-name,
    .pxp-candiadates-card-1-location,
    .pxp-candiadates-card-1-bottom {
        margin-top: 20px;
    }
    .pxp-plans-card-1 {
        padding: 20px;
    }
    .pxp-plans-card-1-list,
    .pxp-plans-card-1-bottom {
        margin-top: 30px;
    }
    .pxp-plans-card-1-price {
        margin-top: 10px;
    }
    .pxp-list-side-filter-header-label {
        font-size: 18px;
    }
    .pxp-jobs-list-side-filter h3 {
        font-size: 16px;
    }
    .pxp-jobs-list-side-filter {
        padding: 20px;
    }
    .pxp-jobs-card-2-top {
        display: block;
    }
    .pxp-jobs-card-2-info {
        margin-left: 0;
        margin-top: 10px;
    }
    .pxp-jobs-card-2-bottom {
        display: block;
    }
    .pxp-jobs-card-4 {
        padding: 20px;
    }
    .pxp-jobs-card-4-company-top {
        display: block;
    }
    .pxp-jobs-card-4-info {
        padding-left: 0;
        margin-top: 10px;
    }
    .pxp-jobs-tab-pane-logo {
        width: 80px;
        height: 80px;
        margin-top: -40px;
    }
    .pxp-jobs-tab-pane-content h3 {
        font-size: 22px;
    }
    .pxp-single-job-cover-logo {
        margin-top: -40px;
        margin-left: 40px;
    }
    .pxp-single-job-content h1 {
        font-size: 22px;
    }
    .pxp-companies-list-side-filter {
        padding: 20px;
    }
    .pxp-companies-list-side-filter h3 {
        font-size: 16px;
    }
    .pxp-single-company-hero-content {
        display: block;
    }
    .pxp-single-company-hero-title {
        margin-left: 0;
        margin-top: 10px;
    }
    .pxp-single-company-hero-caption {
        bottom: 40px;
    }
    .pxp-single-company-side-panel {
        padding: 20px;
    }
    .pxp-single-job-side-panel {
        padding: 20px;
    }
    .pxp-candidates-list-side-filter {
        padding: 20px;
    }
    .pxp-candidates-list-side-filter h3 {
        font-size: 16px;
    }
    .pxp-single-candidate-hero-content {
        display: block;
    }
    .pxp-single-candidate-hero-name {
        margin-left: 0;
        margin-top: 10px;
    }
    .pxp-single-candidate-hero-caption {
        bottom: 40px;
    }
    .pxp-single-candidate-side-panel {
        padding: 20px;
    }
    .pxp-single-candidate-hero.pxp-boxed .pxp-single-candidate-hero-caption {
        right: 30px;
    }
    .pxp-featured-posts-item-summary {
        display: none;
    }
    .pxp-featured-posts-item-title {
        font-size: 22px;
    }
    .pxp-blogs-list-side-panel {
        padding: 20px;
    }
    .pxp-hero h1 {
        font-size: 24px;
    }
    .pxp-categories-card-3-text {
        padding: 10px 20px;
    }
}

}