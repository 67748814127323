.select-data-primary {
    position: relative;
    line-height: 38px;
}

.select-data-primary span {
    position: absolute;
    left: 0;
    bottom: -18px;
    font-size: 13px;
}

@media only screen and (max-width: 576px) {
    .select-data-primary span {
        position: absolute;
        left: calc( 50% - 36px );
        bottom: -18px;
        font-size: 13px;
    }
}

@media only screen and (min-width: 576px) {
    .select-data-primary span {
        position: absolute;
        left: 0;
        bottom: -18px;
        font-size: 13px;
    }
}

.select-label-bottom {
    color: white;
    font-style: italic;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -1px;
}