.dropdown-menu.show {
    position: unset;
    box-shadow: none;
    width: 100%;
}


.mobile .dropdown-menu .nk-notification {
    max-height: unset !important;
}

.header-title {
    color: #798bff;
}

.desktop .dropdown-menu .nk-notification {
    max-height: 542px !important;
}

.nk-header-brand .header-title ion-icon {
    height: 49px;
    width: 140px;
}

.jobster .pxp-header.pxp-bigger.pxp-is-hidden .pxp-header-container {
    height: 0 !important;
}

.jobster .pxp-header.pxp-bigger .pxp-header-container {
    overflow: hidden;
}