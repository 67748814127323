
@media only screen and (min-width: 576px) {
    .pxp-container .pxp-single-job-content {
        margin-top: 150px !important;
    }
    .pxp-container .pxp-single-job-side-panel {
        z-index: 1;
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
        position: sticky;
        top: 130px;
    }
    .single-job-frame {
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
        border-radius: 30px;
    }
}


/* @media only screen and (max-width: 576px) {
    .pxp-container .pxp-single-job-content {
        margin-top: 150px !important;
    }
} */

.blured {
    filter: blur(4px) !important;
}

.pxp-container .pxp-single-job-content .btn.rounded-pill.pxp-section-cta {
    line-height: 27px;
}


.pxp-container .pxp-single-job-content .fa.fa-angle-right {
    font-size: 36px;
    width: 35px;
}

.pxp-container .pxp-single-job-content .pxp-single-job-side-company-profile .pxp-single-job-side-company-name {
    font-size: 21px;
}

.pxp-container .pxp-single-job-content .pxp-single-job-side-company-profile {
    margin-left: 0;
}